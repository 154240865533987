import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { SurveyWithResults } from "../../types";
import RespondentDistribution from "./RespondentDistribution";
import RespondentCategory from "./RespondentCategory";
import Container from "../../components/Container";
import TrendChart from "../../components/TrendChart";
import GroupCategoryComparison from "./GroupCategoryComparison";
import Divider from "../../components/Divider";
import { ChevronDownIcon, LockClosedIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import { displayMonth } from "../../hooks/helpers";
import Dropdown from "../../components/Dropdown";
import ReportHelp from "./ReportHelp";
import { ArrowDownTrayIcon, CheckIcon } from "@heroicons/react/24/outline";
import Tooltip from "../../components/Tooltip";
import {
  getStressCategoryDatasets,
  countGreen,
  countRed,
  countYellow,
  getResponseRate,
  getPreviousSurvey,
} from "./helpers";
import Checkbox from "./Checkbox";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/react";
import GroupParticipationRates from "./GroupParticipationRates";
import CoreStrengthIndicator from "./CoreStrengthIndicator";
import StressBreakdownBar from "./StressBreakdownBar";
import SIValue from "./SIValueGuage";
import { useAuth } from "../../contexts/authContext";
import { useCompany } from "../../contexts/companyContext";
import { useSearchParams } from "react-router-dom";

export default function Report(props: { surveyId: number; surveys: SurveyWithResults[] }) {
  const { surveyId, surveys } = props;

  const navigate = useNavigate();
  const { sessionInfo } = useAuth();
  const { companyId } = useCompany();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [selectedGroupFilters, setSelectedGroupFilters] = useState<number[]>([]);
  const [selectedStrataJobTitleFilters, setSelectedStrataJobTitleFilters] = useState<number[]>([]);
  const [showCustomGroups, setShowCustomGroups] = useState<boolean>(false);
  const [openHelp, setOpenHelp] = useState<boolean>(false);

  const [loadingPDF, setLoadingPDF] = useState<boolean>(false);

  // scroll to hash id (ex. /assessment/1#group)
  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        const offset = 30;
        const top = element.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({ top: top - offset, behavior: "smooth" });
      }
    }
  }, [location]);

  // set group or strata job title filters from query parameter (ex. /assessment/1?groupId=1)
  useEffect(() => {
    const groupId = searchParams.get("groupId");
    const strataJobTitleId = searchParams.get("strataJobTitleId");

    if(groupId) {
      setSelectedGroupFilters([parseInt(groupId)]);
      setShowCustomGroups(true);
    }
    if(strataJobTitleId) {
      setSelectedStrataJobTitleFilters([parseInt(strataJobTitleId)]);
      setShowCustomGroups(false)
    }
  }, [searchParams]);

  async function getPDFResults() {
    setLoadingPDF(true);
    const response = await fetch(`${process.env.REACT_APP_API_URL}/company/surveys/pdf/${surveyId}`, {
      method: "GET",
      headers: {
        Authorization: sessionInfo?.idToken.toString() ?? "",
        "Company-Id": companyId ? companyId.toString() : "",
      },
    });

    const arrayBuffer = await response.arrayBuffer();
    const uint8Array = new Uint8Array(arrayBuffer);
    const blob = new Blob([uint8Array], { type: "application/pdf" });
    const blobUrl = URL.createObjectURL(blob);

    // open the PDF in a new tab
    window.open(blobUrl, "_blank");
    setLoadingPDF(false);
  }
  const survey = surveys.find((s) => s.survey_id === surveyId);
  const completedSurveys = surveys.filter((s) => new Date(s.end_date) < new Date() && s.results);

  useEffect(() => {
    if(survey) {
      if(survey.groupResults && survey.groupResults.length > 0) {
        setShowCustomGroups(true);
      }
    }
  }, [survey])

  const groupDropdownOptions = ["Custom Groups", "Clinical Groups"];

      
  if (!surveyId || !survey || !survey.results || !survey.groupResults || !survey.strataJobTitleResults) {
    return (
      <div>
        <div className="">
          <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">Mental Health Breakdown</h2>
        </div>
        <div className="flex items-center justify-center h-96">
          <div className="flex flex-col justify-center content-center items-center">
            <LockClosedIcon className="h-14 w-14 mb-4 bg-yellow-400 rounded-full p-4" />
            <h1 className="text-xl font-bold">No Breakdown Results - Yet</h1>
            <p className="mt-4 text-gray-500 text-center">Come back after your first assessment is complete</p>
          </div>
        </div>
      </div>
    );
  } else {
    const { results, groupResults, strataJobTitleResults } = survey;

    const previousSurvey = getPreviousSurvey(surveys, survey);

    const siValue = results.si_value;
    const greenCount = countGreen(results);
    const yellowCount = countYellow(results);
    const redCount = countRed(results);
    const disengagedCount = results.disengaged_count;
    const responseRate = getResponseRate(results);

    // calculate datasets for stress category by Group
    const stressCategoryDatasets = getStressCategoryDatasets(completedSurveys, showCustomGroups, selectedStrataJobTitleFilters, selectedGroupFilters);

    return (
      <div id="report" className="">
        <div className="mb-6 flex justify-between">
          {completedSurveys.length > 1 ? (
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <MenuButton className="inline-flex w-full justify-center items-center gap-x-1 text-xl sm:text-2xl font-bold tracking-tight text-gray-900 px-2 py-1 rounded-md hover:bg-stone-200">
                  {displayMonth(new Date(survey.start_date).getMonth())} {new Date(survey.start_date).getFullYear()} Results
                  <ChevronDownIcon aria-hidden="true" className="-mr-1 h-7 w-7 text-gray-700" />
                </MenuButton>
              </div>

              <MenuItems
                transition
                className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                {completedSurveys
                  .sort((a, b) => new Date(b.start_date).getTime() - new Date(a.start_date).getTime())
                  .map((s, index) => (
                    <div key={index} className="">
                      <MenuItem>
                        <div
                          className={`flex items-center px-4 py-2 ${
                            s.survey_id === survey.survey_id ? "bg-gray-100 cursor-default" : "hover:bg-gray-100 cursor-pointer"
                          }`}
                          onClick={() => navigate(`/assessments/${s.survey_id}`)}
                        >
                          <div className={`block text-sm text-gray-700`}>
                            {displayMonth(new Date(s.start_date).getMonth())} {new Date(s.start_date).getFullYear()}
                          </div>
                          {s.survey_id === survey.survey_id && <CheckIcon className="size-4 text-gray-400 ml-1" />}
                        </div>
                      </MenuItem>
                    </div>
                  ))}
              </MenuItems>
            </Menu>
          ) : (
            <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">
              {displayMonth(new Date(survey.start_date).getMonth())} {new Date(survey.start_date).getFullYear()} Results
            </h2>
          )}
          <div className="flex gap-3">
            {loadingPDF ? (
              <svg className="h-5 w-5 animate-spin text-gray-600 mt-1" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              <Tooltip message="Download a PDF version of this report" position="below">
                <ArrowDownTrayIcon className="w-6 h-6 text-gray-600 hover:text-gray-900 cursor-pointer" onClick={getPDFResults} />
              </Tooltip>
            )}
          </div>
        </div>

        <div>
          <RespondentCategory
            responseRate={responseRate}
            green={greenCount}
            yellow={yellowCount}
            red={redCount}
            gray={disengagedCount}
            totalRecipients={results.total_recipients}
          />
        </div>

        <div className="my-8">
          <StressBreakdownBar green={greenCount} yellow={yellowCount} red={redCount} disengaged={disengagedCount} />
        </div>

        <ReportHelp openHelp={openHelp} setOpenHelp={setOpenHelp} />

        <div className="grid grid-cols-1 md:grid-cols-6 gap-3">
          <Container
            title="Strata Intel Value"
            tooltip="The Strata Intel Value is a measure of the strain on the overall team."
            className="flex flex-col col-span-1 md:col-span-2 w-full"
          >
            <SIValue siValue={siValue} lastSIValue={previousSurvey?.results ? previousSurvey.results.si_value : null} />
          </Container>
          <Container
            title="Team Core Strength Indicator"
            tooltip="Measures how many employees are working in their optimal stress zone—where they perform at their best without burning out. These are the employees who are most likely to be engaged and productive and push others to do the same."
            className="flex flex-col col-span-1 md:col-span-4 w-full"
          >
            <CoreStrengthIndicator greenCount={greenCount} yellowCount={yellowCount} totalRecipients={results.total_recipients} />
          </Container>
        </div>

        <div className="mt-8 mb-8">
          <Divider heading={""} />
        </div>

        <div className="flex items-center mb-4">
          <div className="mr-4">
            <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">How is Your Team Experiencing Stress</h2>
          </div>
          <div className="relative group">
            <QuestionMarkCircleIcon className="h-6 w-6 cursor-pointer" onClick={() => setOpenHelp(!openHelp)} />

            <div className="absolute right-1/2 transform translate-x-1/2 -top-[70px] hidden w-48 p-3 text-sm text-white bg-black rounded-lg group-hover:block">
              Learn more about what this measures
              <div className="absolute bottom-[-6px] left-1/2 transform -translate-x-1/2 w-3 h-3 bg-black rotate-45"></div>
            </div>
          </div>
        </div>

        <Container title="" className="col-span-3 h-96 mb-4">
          <RespondentDistribution results={results} />
        </Container>

        <div id="groups" className="flex mb-4 mt-12 scroll-mt-8">
          <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl mr-4">Group Breakdown</h2>
          {groupResults && groupResults.length > 0 && (
            <div className="">
              <Dropdown
                options={groupDropdownOptions}
                selected={showCustomGroups ? groupDropdownOptions[0] : groupDropdownOptions[1]}
                setSelected={(selected) => (selected === groupDropdownOptions[0] ? setShowCustomGroups(true) : setShowCustomGroups(false))}
              />
            </div>
          )}
        </div>

        <div className="grid grid-cols-5 gap-4 h-96">
          <Container title="Participation Rates" className="lg:col-span-1 col-span-5 w-full h-96 pb-2">
            <GroupParticipationRates results={showCustomGroups ? groupResults : strataJobTitleResults} />
          </Container>

          <Container title="" className="relative lg:col-span-4 col-span-5 h-96 w-full">
            <GroupCategoryComparison results={showCustomGroups ? groupResults : strataJobTitleResults} />
          </Container>
        </div>

        <div id="stressTrend" className="mb-4 mt-12">
          <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">Stress Trend Over Time</h2>
        </div>
        <div className="mb-6 grid grid-cols-5 gap-4 h-96">
          <Container className={`lg:col-span-4 col-span-5 w-full h-96`}>
            <TrendChart
              datasets={stressCategoryDatasets}
              stepSize={1}
              suggestedMin={0}
              suggestedMax={5}
              calcType="add"
              yAxisUnits="Respondents"
              showPercentageToggle={true}
              showCompanyEvents={true}
            />
          </Container>
          <div className="w-full h-96 flex flex-col col-span-5 lg:col-span-1">
            {groupResults.length > 0 && (
              <Dropdown
                options={groupDropdownOptions}
                selected={showCustomGroups ? groupDropdownOptions[0] : groupDropdownOptions[1]}
                setSelected={(selected) => (selected === groupDropdownOptions[0] ? setShowCustomGroups(true) : setShowCustomGroups(false))}
                className="mb-2 z-20"
              />
            )}
            <Container title="Filter" className={`flex-1 w-full pb-0 ${groupResults.length > 0 ? "h-5/6" : ""}`}>
              <div className="overflow-scroll pl-1 mt-4 h-5/6">
                {showCustomGroups
                  ? groupResults.map((group) => (
                      <Checkbox
                        key={group.group_id}
                        id={group.group_id}
                        name={group.group_name}
                        checked={!!selectedGroupFilters.find((filter) => filter === group.group_id)}
                        setChecked={(checked: boolean) => {
                          if (checked) {
                            setSelectedGroupFilters((prevSelected) => [...prevSelected, group.group_id]);
                          } else {
                            setSelectedGroupFilters((prevSelected) => prevSelected.filter((item) => item !== group.group_id));
                          }
                        }}
                      />
                    ))
                  : strataJobTitleResults.map((strataJobTitle) => (
                      <Checkbox
                        key={strataJobTitle.strata_job_title_id}
                        id={strataJobTitle.strata_job_title_id}
                        name={strataJobTitle.strata_job_title_name}
                        checked={!!selectedStrataJobTitleFilters.find((filter) => filter === strataJobTitle.strata_job_title_id)}
                        setChecked={(checked: boolean) => {
                          if (checked) {
                            setSelectedStrataJobTitleFilters((prevSelected) => [...prevSelected, strataJobTitle.strata_job_title_id]);
                          } else {
                            setSelectedStrataJobTitleFilters((prevSelected) => prevSelected.filter((item) => item !== strataJobTitle.strata_job_title_id));
                          }
                        }}
                      />
                    ))}
              </div>
            </Container>
          </div>
        </div>

        <div className="mb-4 mt-12">
          <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">SI Value Over Time</h2>
        </div>
        <Container title="" className={`lg:col-span-4 col-span-5 w-full h-96 relative`}>
          <TrendChart
            title="SI Value"
            showDataLabel={true}
            showTitle={false}
            datasets={[
              {
                label: "SI Value",
                data: surveys
                  .filter((s) => new Date(s.end_date) < new Date() && s.results)
                  .map((s) => {
                    return {
                      value: s.results ? s.results.si_value : null,
                      date: new Date(s.start_date),
                    };
                  }),
                color: "#3b82f6",
              },
            ]}
            suggestedMax={100}
            suggestedMin={0}
            stepSize={20}
            hideLegend={true}
            showCompanyEvents={true}
          />
        </Container>

        <div id="responseRate" className="mb-4 mt-12">
          <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">Response Rate Over Time</h2>
        </div>
        <Container className="lg:col-span-4 md:col-span-3 col-span-5 w-full h-96 relative">
          <TrendChart
            datasets={[
              {
                label: "Response Rate",
                data: completedSurveys
                  .filter((s) => s.results)
                  .map((s) => ({
                    value: getResponseRate(s.results!) / 100, // TrendChart wants response rate as a decimal value
                    date: s.start_date,
                  })),
              },
            ]}
            // stepSize={10}
            suggestedMax={100}
            suggestedMin={0}
            calcType="average"
            displayType="percent"
            yAxisLabel="Response Rate"
            showCompanyEvents={true}
            hideLegend={true}
            showDataLabel={true}
          />
        </Container>
      </div>
    );
  }
}
