import { useEffect, useState, useContext } from "react";
import { AuthContext, useAuth } from "../../contexts/authContext";
import Spinner from "../../components/Spinner";
import { Company, CompanyEvent, CompanySurveySummary, Schedule, Subscription, Survey, SurveyWithResults } from "../../types";
import { useLocation, useNavigate } from "react-router";
import WelcomeModal from "./WelcomeModal";
import PreSurveySteps from "./PreSurveySteps";
import Container from "../../components/Container";
import Gauge from "../Report/SIValueGuage";
import TrendChart from "../../components/TrendChart";
import { useQuery } from "@tanstack/react-query";
import CountdownTimer from "../../components/Countdown";
import { CompanyContext, useCompany } from "../../contexts/companyContext";
import useApi from "../../hooks/useApi";
import Alert from "../../components/Alert";
import HomeStats from "./HomeStats";
import { getCurrentSurvey, getLatestCompletedSurvey } from "../../hooks/helpers";
import AlertList from "./AlertList";
import { formatDistanceToNow } from "date-fns";
import InviteOthers from "./InviteOthers";
import { getPreviousCompletedSurvey } from "../Report/helpers";
import { Link } from "react-router-dom";

export default function Home() {
  const auth = useAuth();
  const companyContext = useCompany();
  const { getApiData, postApiData } = useApi();
  const location = useLocation();
  const navigate = useNavigate();
  const companyId = companyContext.companyId;

  const [showWelcomeModal, setShowWelcomeModal] = useState<boolean>(false);
  const [openFactors, setOpenFactors] = useState<boolean>(false);

  const { isPending: scheduleIsPending, data: schedule } = useQuery<Schedule | null>({
    queryKey: ["schedule"],
    queryFn: () => getApiData(`/company/schedule`),
  });

  const { isPending: paymentInfoIsPending, data: paymentInfo } = useQuery<Subscription>({
    queryKey: ["payment", companyContext.companyId],
    queryFn: () => getApiData(`/company/payment/info`),
    retry: false,
  });

  const { data: company } = useQuery<Company>({
    queryKey: ["company", companyContext.companyId],
    queryFn: () => getApiData(`/company/info`),
  });

  const {
    isPending: surveysIsPending,
    error: surveysError,
    data: surveys,
  } = useQuery<SurveyWithResults[]>({
    queryKey: ["surveysWithResults", companyId],
    queryFn: () => getApiData(`/company/surveys/results`),
    enabled: !!companyId,
  });

  useEffect(() => {
    const urlShowWelcome = new URLSearchParams(location.search).get("welcome");

    if (!scheduleIsPending && !paymentInfoIsPending) {
      if (!schedule || !paymentInfo || paymentInfo.stripe_subscription_status !== "active") {
        setTimeout(() => {
          if (typeof urlShowWelcome === "string") {
            // setShowWelcomeModal(true); Commenting this out for now until we fix welcome modal
          }
        }, 500);
      }
    }
  }, [location.search, paymentInfo, schedule, scheduleIsPending, paymentInfoIsPending]);

  useEffect(() => {
    if (companyContext.showAllAccounts === true) {
      navigate("/accounts");
    }
  }, []);

  const latestCompletedSurvey = surveys ? getLatestCompletedSurvey(surveys) : null;
  const previousCompletedSurvey = surveys ? getPreviousCompletedSurvey(surveys) : null;
  const currentSurvey = surveys ? getCurrentSurvey(surveys) : null;
  const nextSurvey = surveys?.find((s) => new Date(s.start_date) > new Date());

  if (surveysError) {
    return <Alert title="Error" message="An unexpected error occurred, please try again later" type="Error" />;
  }
  if (surveysIsPending) {
    return <Spinner />;
  }
  return (
    <>
      <WelcomeModal open={showWelcomeModal} setOpen={setShowWelcomeModal} />
      <div className="min-h-full">
        <div className="flex justify-between items-center w-full">
          <div>
            <h1 className="text-2xl font-bold">Hi, {auth.userAttributes.given_name}</h1>
            {company?.has_assessments && !latestCompletedSurvey ? (
              <h1 className="mt-2 mb-6">Complete these steps to set up your account</h1>
            ) : (
              <h1 className="mt-2 mb-6">Here is how your team is doing</h1>
            )}
          </div>
        </div>
        {company?.has_assessments && !latestCompletedSurvey && <PreSurveySteps />}

        {company?.has_assessments && latestCompletedSurvey && <HomeStats latestCompletedSurvey={latestCompletedSurvey} previousCompletedSurvey={previousCompletedSurvey} surveys={surveys} />}

        <div className="mt-10 sm:grid grid-cols-10 gap-x-6">
          <div className="col-span-7">
            <h3 className="text-lg font-semibold mb-3">Alerts and Action Items</h3>
            <AlertList />
          </div>
          <div className="col-span-3 mt-8 sm:mt-0">
            {nextSurvey && (
              <div>
                <h3 className="text-lg font-semibold mb-3">Next Assessment</h3>
                <Container>
                  <div className="text-sm text-gray-500">Starting in</div>
                  <div className="text-2xl font-semibold text-gray-900 mt-2">
                    {formatDistanceToNow(new Date(nextSurvey.start_date), {
                      addSuffix: false,
                      includeSeconds: true,
                    })}
                  </div>
                </Container>
              </div>
            )}
            {/* <div className="mt-8">
              <h3 className="text-lg font-semibold mb-3">Operational Playbook</h3>
              <Container>
                <div>
                  <div className="text-sm text-gray-500 mb-4">How to Improve SI Value</div>
                  <div className="mb-6">
                    <div className="flex flex-col gap-4">
                      <div className="bg-indigo-50 rounded-lg p-4 hover:bg-indigo-100 transition-colors cursor-pointer">
                        <div className="flex items-center gap-3">
                          <div className="p-2 bg-indigo-100 rounded-md">
                            <svg className="w-5 h-5 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                              />
                            </svg>
                          </div>
                          <h3 className="text-sm font-medium text-gray-900">Meaningful Work</h3>
                        </div>
                      </div>

                      <div className="bg-purple-50 rounded-lg p-4 hover:bg-purple-100 transition-colors cursor-pointer">
                        <div className="flex items-center gap-3">
                          <div className="p-2 bg-purple-100 rounded-md">
                            <svg className="w-5 h-5 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                              />
                            </svg>
                          </div>
                          <h3 className="text-sm font-medium text-gray-900">Clinical Spirituality</h3>
                        </div>
                      </div>

                      <div className="bg-blue-50 rounded-lg p-4 hover:bg-blue-100 transition-colors cursor-pointer">
                        <div className="flex items-center gap-3">
                          <div className="p-2 bg-blue-100 rounded-md">
                            <svg className="w-5 h-5 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                              />
                            </svg>
                          </div>
                          <h3 className="text-sm font-medium text-gray-900">SPARC Relationships</h3>
                        </div>
                      </div>

                      <div className="bg-amber-50 rounded-lg p-4 hover:bg-amber-100 transition-colors cursor-pointer">
                        <div className="flex items-center gap-3">
                          <div className="p-2 bg-amber-100 rounded-md">
                            <svg className="w-5 h-5 text-amber-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                          </div>
                          <h3 className="text-sm font-medium text-gray-900">Hobbies & Interests</h3>
                        </div>
                      </div>

                      <div className="bg-green-50 rounded-lg p-4 hover:bg-green-100 transition-colors cursor-pointer">
                        <div className="flex items-center gap-3">
                          <div className="p-2 bg-green-100 rounded-md">
                            <svg className="w-5 h-5 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                            </svg>
                          </div>
                          <h3 className="text-sm font-medium text-gray-900">Physical Health</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Link to="/operational" className="flex justify-end right-0 items-center gap-1 text-xs font-medium text-strataBlue hover:text-blue-700 transition-colors">
                    View details
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                    </svg>
                  </Link>
                </div>
              </Container>
            </div> */}

            {currentSurvey && (
              <div>
                <h3 className="text-lg font-semibold mb-3">Current Assessment</h3>
                <Container>
                  <div className="text-sm text-gray-500">Closing in</div>
                  <div className="text-2xl mt-2">
                    {formatDistanceToNow(new Date(currentSurvey.end_date), {
                      addSuffix: false,
                      includeSeconds: true,
                    })}
                  </div>
                </Container>
              </div>
            )}
          </div>
          <div className="col-span-7">
            <h3 className="text-lg font-semibold mb-3 mt-10">Invite Others</h3>
            <Container>
              <InviteOthers />
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
