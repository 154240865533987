import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import { CompanyHealth, Participant, RiskInput, SurveyWithResults } from "../../types";
import { useQuery } from "@tanstack/react-query";
import { useCompany } from "../../contexts/companyContext";
import useApi from "../../hooks/useApi";
import { calculateEconomicRisk } from "../../hooks/helpers";
import { Link } from "react-router-dom";
import { formatDistanceToNow, intervalToDuration } from "date-fns";
import SIValueGuage from "../Report/SIValueGuage";
import TrendChart from "../../components/TrendChart";

export default function HomeStats(props: { latestCompletedSurvey: SurveyWithResults; previousCompletedSurvey: SurveyWithResults | null; surveys: SurveyWithResults[] }) {
  const { latestCompletedSurvey, previousCompletedSurvey, surveys } = props;

  const { companyId } = useCompany();
  const { getApiData } = useApi();

  const { data: riskInputs } = useQuery<RiskInput>({
    queryKey: ["riskInputs", companyId],
    queryFn: () => getApiData(`/company/info/risk/inputs`),
    enabled: !!companyId,
  });

  const { data: participants } = useQuery<Participant[]>({
    queryKey: ["participants", companyId],
    queryFn: async () => getApiData(`/company/users`),
    initialData: [],
  });

  const { data: companyHealth } = useQuery<CompanyHealth[]>({
    queryKey: ["companyHealth", companyId],
    queryFn: async () => getApiData(`/company/users/company-health`),
    initialData: [],
  });

  if (!latestCompletedSurvey.results) {
    return <div></div>;
  }

  const siValue = latestCompletedSurvey.results.si_value;
  const previousSiValue = previousCompletedSurvey?.results ? previousCompletedSurvey.results.si_value : null;
  const siValueChange = previousSiValue ? siValue - previousSiValue : null;

  const economicRisks = calculateEconomicRisk(latestCompletedSurvey.results, riskInputs);
  const previousEconomicRisk = previousCompletedSurvey?.results ? calculateEconomicRisk(previousCompletedSurvey.results, riskInputs) : null;
  const economicRiskChange = previousEconomicRisk ? economicRisks.total - previousEconomicRisk.total : null;

  const lastParticipantUpdate =
    companyHealth.length > 0
      ? new Date(
          companyHealth.reduce(function (a, b) {
            return a.created_at > b.created_at ? a : b;
          }).created_at
        )
      : null;

  const durationSinceLastParticipantUpdate = lastParticipantUpdate
    ? intervalToDuration({
        start: new Date(),
        end: lastParticipantUpdate,
      })
    : null;

  return (
    <div>
      <dl className="grid grid-cols-1 md:grid-cols-10 gap-5 sm:gap-6 h-auto md:h-[300px]">
        {/* SI Value and Trend Chart - Full width on mobile */}
        <div className="relative overflow-hidden rounded-lg bg-white px-4 py-5 shadow border sm:px-8 sm:pt-6 col-span-full md:col-span-7">
          <dt>
            <p className="truncate text-sm font-medium text-gray-500">SI Value Overview</p>
          </dt>
          <div className="flex flex-col md:flex-row">
            {/* SI Value Gauge */}
            <div className="w-full md:w-1/3 flex flex-col items-center justify-center h-[250px] p-4">
              <div className="w-full h-full">
                <SIValueGuage siValue={siValue} lastSIValue={previousSiValue} />
              </div>
            </div>

            {/* Trend Chart */}
            <div className="w-full md:w-2/3 h-[220px]">
              <TrendChart
                title="SI Value"
                showDataLabel={true}
                showTitle={false}
                datasets={[
                  {
                    label: "SI Value",
                    data: surveys
                      .filter((s) => new Date(s.end_date) < new Date() && s.results)
                      .map((s) => ({
                        value: s.results ? s.results.si_value : null,
                        date: new Date(s.start_date),
                      })),
                    color: "#3b82f6",
                    chartType: "line",
                  },
                  {
                    label: "Participation Rate",
                    data: surveys
                      .filter((s) => new Date(s.end_date) < new Date() && s.results)
                      .map((s) => ({
                        value: s.results ? (s.results.total_respondents / s.results.total_recipients) * 100 : null,
                        date: new Date(s.start_date),
                      })),
                    color: "rgba(100, 116, 139, 0.18)",
                    chartType: "bar",
                  },
                ]}
                suggestedMax={100}
                suggestedMin={0}
                stepSize={20}
                hideLegend={false}
                showCompanyEvents={false}
                hideRangeSelector={true}
  
              />
            </div>
          </div>
        </div>

        {/* Right side cards - Full width on mobile */}
        <div className="flex flex-col gap-5 sm:gap-6 col-span-full md:col-span-3">
          {/* Economic Impact */}
          <div className="relative overflow-hidden rounded-lg bg-white px-4 py-5 shadow border sm:px-6 flex-1">
            <dt>
              <p className="truncate text-sm font-medium text-gray-500">Economic Risk</p>
            </dt>
            <dd className="flex mt-4 items-center gap-7">
              <p className="text-2xl font-semibold text-gray-900">
                {economicRisks.total.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 0,
                })}
              </p>
              {economicRiskChange && (
                <div className="flex-shrink-0">
                  <p className={`${economicRiskChange > 0 ? "text-red-500" : "text-green-500"} flex text-sm font-semibold`}>
                    {economicRiskChange > 0 ? (
                      <ArrowUpIcon aria-hidden="true" className="size-5 shrink-0 self-center text-red-500" />
                    ) : (
                      <ArrowDownIcon aria-hidden="true" className="size-5 shrink-0 self-center text-green-500" />
                    )}

                    <span className="sr-only"> {economicRiskChange > 0 ? "Increased" : "Decreased"} by </span>
                    {Math.abs(economicRiskChange).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 0,
                    })}
                  </p>
                  <p className="text-xs text-gray-500">From last month</p>
                </div>
              )}
            </dd>
            <div className="absolute bottom-3 right-5">
              <Link 
                to="/risk" 
                className="inline-flex items-center gap-1 text-xs font-medium text-strataBlue hover:text-blue-700 transition-colors"
              >
                View details
                <svg 
                  className="w-4 h-4" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </Link>
            </div>
          </div>

          {/* Participants */}
          <div className="relative overflow-hidden rounded-lg bg-white px-4 py-5 shadow border sm:px-6 flex-1">
            <dt>
              <p className="truncate text-sm font-medium text-gray-500">Total Participants</p>
            </dt>
            <dd className="flex mt-4 items-center gap-7">
              <p className="text-2xl font-semibold text-gray-900">{participants.length}</p>
              {lastParticipantUpdate && durationSinceLastParticipantUpdate && (
                <div className="flex-shrink-0">
                  <p className={`text-xs text-gray-500 font-semibold`}>Last Updated</p>
                  <p className={`text-sm ${durationSinceLastParticipantUpdate.months ?? 0 >= 1 ? "text-red-500" : "text-gray-900"}`}>
                    {formatDistanceToNow(lastParticipantUpdate, {
                      addSuffix: true,
                    })}
                  </p>
                </div>
              )}
            </dd>
            <div className="absolute bottom-2 right-5">
              <Link 
                to="/participants" 
                className="inline-flex items-center gap-1 text-xs font-medium text-strataBlue hover:text-blue-700 transition-colors"
              >
                View participants
                <svg 
                  className="w-4 h-4" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </dl>
    </div>
  );
}
