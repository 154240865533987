import { useEffect } from "react";
import { useCompany } from "../../contexts/companyContext";
import useApi from "../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import { CompanySurveyResult } from "../../types";
import { useAuth } from "../../contexts/authContext";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/16/solid";
import { SI_VALUE_UPPER_CUTOFF, SI_VALUE_LOWER_CUTOFF } from "../../constants";
import { getInitialsFromName, getLatestCompletedSurvey } from "../../hooks/helpers";
import { Avatar } from "../Nav/Avatar";
import { getResponseRate, countRed, countYellow, countGreen } from "../Report/helpers";
import { useNavigate } from "react-router";
import Spinner from "../../components/Spinner";

export default function Overview() {
  const { getApiData } = useApi();
  const companyContext = useCompany();
  const { userAttributes } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!companyContext.showAllAccounts) {
      companyContext.setShowAllAccounts(true);
    }
  }, []);

  // Get all surveys for every company that a user is a part of
  const { data: companySurveyResults, isPending } = useQuery<CompanySurveyResult[]>({
    queryKey: ["companySurveyResults", userAttributes.sub],
    queryFn: async () => getApiData(`/user/surveys/results`),
  });

  if(isPending || !companySurveyResults) {
    return <Spinner />
  }

  return (
    <>
      {companySurveyResults.length > 0 && (
        <div>
          <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
            {companySurveyResults.map((company, index) => {

              const latestCompletedSurvey = company.surveys ? getLatestCompletedSurvey(company.surveys) : null;

              return (
                <div key={index}>
                  <div className="overflow-hidden rounded-xl border border-gray-300">
                    <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-blue-50 px-6 py-4">
                      <Avatar
                        slot="icon"
                        initials={getInitialsFromName(company.company_name)}
                        className="bg-strataBlue text-white h-12 w-12 flex-none rounded-lg object-cover ring-1 ring-gray-900/10"
                      />
                      <div>
                        <div className="font-medium leading-6 text-gray-900">{company.company_name}</div>
                        <div className="text-xs text-gray-500">
                          {company.company_state} {company.company_type_name}
                        </div>
                      </div>
                      <Menu as="div" className="relative ml-auto">
                        <MenuButton className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                          <span className="sr-only">Open options</span>
                          <EllipsisHorizontalIcon aria-hidden="true" className="h-5 w-5" />
                        </MenuButton>
                        <MenuItems
                          transition
                          className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                        >
                          <MenuItem>
                            <div
                              onClick={() => {
                                companyContext.setCompanyId(company.company_id);
                                navigate("/home");
                              }}
                              className="cursor-pointer block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                            >
                              View<span className="sr-only">, {company.company_name}</span>
                            </div>
                          </MenuItem>
                          <MenuItem>
                            <div
                              onClick={() => {
                                companyContext.setCompanyId(company.company_id);
                                navigate("/settings");
                              }}
                              className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                            >
                              Settings<span className="sr-only">, {company.company_name}</span>
                            </div>
                          </MenuItem>
                        </MenuItems>
                      </Menu>
                    </div>
                    <dl className="divide-y divide-gray-200 px-6 text-sm leading-6 bg-white">
                      <div className="flex justify-between items-center gap-x-4 py-4">
                        <div className="flex">
                          <dt className="text-gray-500 mr-1 flex items-center">SI Value:</dt>
                          <dd className="text-gray-700">
                            {latestCompletedSurvey && latestCompletedSurvey.results ? (
                              <div className="">
                                <span
                                  className={`text-xl font-bold ${
                                    latestCompletedSurvey.results.si_value > SI_VALUE_UPPER_CUTOFF
                                      ? "text-green-500"
                                      : latestCompletedSurvey.results.si_value > SI_VALUE_LOWER_CUTOFF
                                      ? "text-yellow-500"
                                      : "text-red-500"
                                  }`}
                                >
                                  {latestCompletedSurvey.results.si_value}
                                </span>
                              </div>
                            ) : (
                              <div className="text-gray-400">No Results</div>
                            )}
                          </dd>
                        </div>
                        <div className="flex">
                          <dt className="text-gray-500 mr-1">Response Rate:</dt>
                          <dd className="text-gray-800 font-semibold">
                            {latestCompletedSurvey?.results ? `${getResponseRate(latestCompletedSurvey.results).toFixed(0)}%` : "N/A"}
                          </dd>
                        </div>
                      </div>

                      <div className="flex justify-between gap-x-4 py-4">
                        <dt className="text-gray-500">Response Distribution</dt>
                        {latestCompletedSurvey?.results ? (
                          <dd className="flex items-start gap-x-1">
                            <div className="rounded-md text-xs font-medium ring-1 ring-red-600 ring-inset bg-red-500 text-white w-8 h-6 flex items-center justify-center">
                              {countRed(latestCompletedSurvey.results)}
                            </div>
                            <div className="rounded-md text-xs font-medium ring-1 ring-yellow-600 ring-inset bg-yellow-500 text-white w-8 h-6 flex items-center justify-center">
                              {countYellow(latestCompletedSurvey.results)}
                            </div>
                            <div className="rounded-md text-xs font-medium ring-1 ring-green-600 ring-inset bg-green-500 text-white w-8 h-6 flex items-center justify-center">
                              {countGreen(latestCompletedSurvey.results)}
                            </div>
                            <div className="rounded-md text-xs font-medium ring-1 ring-gray-500 ring-inset bg-gray-400 text-white w-8 h-6 flex items-center justify-center">
                              {latestCompletedSurvey.results.disengaged_count}
                            </div>
                          </dd>
                        ) : (
                          <div className="text-gray-400">No Results</div>
                        )}
                      </div>
                    </dl>
                  </div>
                </div>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
}
