import React, { createContext, useState, ReactNode, useContext } from "react";
import NotificationsList from "../components/Notifications/NotificationList";

type NotificationType = "success" | "error" | "warn";

interface Notification {
  id: number;
  title: string;
  message: string;
  type: NotificationType;
}

interface NotificationContextProps {
  notifications: Notification[];
  addNotification: (title: string, message: string, type?: NotificationType) => void;
  removeNotification: (id: number) => void;
}

const NotificationContext = createContext<NotificationContextProps | undefined>(undefined);

const NotificationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const addNotification = (title: string, message: string, type: NotificationType = "success") => {
    const id = Date.now();
    setNotifications((prevNotifications) => [...prevNotifications, { id, title, message, type }]);
  };

  const removeNotification = (id: number) => {
    setNotifications((prevNotifications) => prevNotifications.filter((notification) => notification.id !== id));
  };

  return (
    <NotificationContext.Provider value={{ notifications, addNotification, removeNotification }}>
      <NotificationsList />
      {children}
    </NotificationContext.Provider>
  );
};

const useNotifications = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error("useNotifications must be used within a NotificationProvider");
  }
  return context;
};

export { NotificationProvider, useNotifications };
