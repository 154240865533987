import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { ReactNode } from "react";

export default function Tooltip(props: { message: string; position?: "above" | "below"; children?: ReactNode }) {
  const position = props.position ?? "above";

  return (
    <div className="inline-flex">
      <div className="group relative flex items-center">
        <div className="text-gray-400 hover:text-gray-600 transition-colors duration-200">
          {props.children ? props.children : <InformationCircleIcon className="h-5 w-5" aria-hidden="true" />}
        </div>
        
        <div 
          className={`
            invisible group-hover:visible opacity-0 group-hover:opacity-100
            transition-all duration-200
            absolute left-1/2 -translate-x-1/2
            ${position === "above" ? "bottom-[calc(100%+5px)]" : "top-[calc(100%+5px)]"}
            w-max max-w-[250px] px-3 py-2
            bg-white rounded-lg shadow-lg
            text-sm text-gray-600 text-center
            ring-1 ring-gray-200
          `}
        >
          {props.message}
          <div 
            className={`
              absolute left-1/2 -translate-x-1/2
              ${position === "above" ? "bottom-[-4px]" : "top-[-4px]"}
              h-2 w-2 rotate-45
              bg-white
              ring-1 ${position === "above" ? "ring-b ring-r" : "ring-t ring-l"} ring-gray-200
            `}
          />
        </div>
      </div>
    </div>
  );
}
