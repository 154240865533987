import { calculateEconomicRisk, calculateEmployeeTurnover, calculateMajorCrisis, calculateSequntialTask, calculateStressCategoryCounts, calculateWorkersCompensation } from "../../hooks/helpers";
import { useQuery } from "@tanstack/react-query";
import { Dataset, EconomicRiskCosts, Respondent, RiskInput, Survey, SurveyWithResults } from "../../types";
import { useContext, useState } from "react";
import { CompanyContext } from "../../contexts/companyContext";
import useApi from "../../hooks/useApi";
import EconomicImpactTable from "../Home/EconomicImpactTable";
import TrendChart from "../../components/TrendChart";
import colors from "tailwindcss/colors";
import { RadioGroup, Radio } from "@headlessui/react";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

export default function EconomicImpact(props: {
  economicRiskCosts: EconomicRiskCosts;
  surveys: SurveyWithResults[];
  riskInputs?: RiskInput
}) {
  const { economicRiskCosts, surveys, riskInputs } = props;

  const filterOptions = ["Turnover", "Sequential Task", "Workers Comp"];

  const [selectedMetricFilters, setSelectedMetricFilters] = useState<Array<String>>(filterOptions);
  const [selectedSince, setSelectedSince] = useState<string>("Last Month");

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  const handleFilterChange = (filter: any) => {
    setSelectedMetricFilters((prevFilters: any[]) => {
      if (prevFilters.includes(filter)) {
        // Remove the filter if it’s already selected
        return prevFilters.filter((f: any) => f !== filter);
      } else {
        // Add the filter if it’s not selected
        return [...prevFilters, filter];
      }
    });
  };

  let totalBalanceTemp = 0;
  if (selectedMetricFilters.includes("Sequential Task")) {
    totalBalanceTemp += economicRiskCosts.sequentialTask;
  }
  if (selectedMetricFilters.includes("Turnover")) {
    totalBalanceTemp += economicRiskCosts.employeeTurnover;
  }
  if (selectedMetricFilters.includes("Workers Comp")) {
    totalBalanceTemp += economicRiskCosts.workersComp;
  }

  const stressCategoryDatasets: Dataset[] = [
    {
      label: "Sequential Task",
      data: [],
      color: colors.purple[400],
    },
    {
      label: "Turnover",
      data: [],
      color: colors.yellow[400],
    },
    {
      label: "Workers Comp",
      data: [],
      color: colors.green[400],
    },
    {
      label: "Total",
      data: [],
      color: colors.black,
    },
  ];

  // Get the data for each survey and add it to the datasets for the trend graph
  for (const survey of surveys) {
    if (new Date(survey.end_date) > new Date() || !survey.results) {
      continue; // skip surveys in future
    }

    const riskCosts = calculateEconomicRisk(survey.results, riskInputs)

    stressCategoryDatasets[0].data.push({ value: riskCosts.sequentialTask, date: new Date(survey.start_date) });
    stressCategoryDatasets[1].data.push({ value: riskCosts.employeeTurnover, date: new Date(survey.start_date) });
    stressCategoryDatasets[2].data.push({ value: riskCosts.workersComp, date: new Date(survey.start_date) });

    stressCategoryDatasets[3].data.push({
      value: riskCosts.total,
      date: new Date(survey.start_date),
    });
  }



  const filteredDatasets = stressCategoryDatasets.filter((dataset) => selectedMetricFilters.includes(dataset.label));

  let selectedSinceMaxLength: number = 0;

  if (selectedSince == "Last Month") {
    selectedSinceMaxLength = 2;
  }
  if (selectedSince == "Last Quarter") {
    selectedSinceMaxLength = 3;
  }
  if (selectedSince == "Last Year") {
    selectedSinceMaxLength = 12;
  }

  let valuesToCompare: { metricName: string; compareValue: number }[] = [];

  for (const metric of stressCategoryDatasets) {

    metric.data.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    if (metric.data.length >= selectedSinceMaxLength) {
      valuesToCompare.push({ metricName: metric.label, compareValue: metric.data[metric.data.length - selectedSinceMaxLength].value });
    } else {
      valuesToCompare.push({ metricName: metric.label, compareValue: 0 });
    }
  }

  const sequentialTaskPercentChange = valuesToCompare[0].compareValue;
  const TurnOverPercentageChange = valuesToCompare[1].compareValue;
  const workersCompensationPercentageChange = valuesToCompare[2].compareValue;

  const sequentialTaskValueChange = economicRiskCosts.sequentialTask - valuesToCompare[0].compareValue;
  const TurnOverValueChange = economicRiskCosts.employeeTurnover - valuesToCompare[1].compareValue;
  const workersCompensationValueChange = economicRiskCosts.workersComp - valuesToCompare[2].compareValue;

  // Economic Metrics for the latest completed Assessment
  let stats: { name: string; value: number; change: number | null; percentageChange: number, show: boolean }[] = [
    {
      name: "Turnover",
      value: economicRiskCosts.employeeTurnover,
      change: TurnOverValueChange,
      percentageChange: TurnOverPercentageChange,
      show: true
    },
    {
      name: "Sequential Task Error",
      value: economicRiskCosts.sequentialTask,
      change: sequentialTaskValueChange,
      percentageChange: sequentialTaskPercentChange,
      show: true
    },
    {
      name: "Workers Compensation Claims",
      value: economicRiskCosts.workersComp,
      change: workersCompensationValueChange,
      percentageChange: workersCompensationPercentageChange,
      show: true
    },
  ];

  return (
    <div className="mx-auto">
      <div className="flex flex-col">
        <p className="text-gray-400 font-semibold text-md mb-3">Current Total Risk Balance</p>
        <h2 className="text-3xl font-bold ">{formatter.format(totalBalanceTemp)}</h2>
      </div>
      <div className="h-60">
        <TrendChart datasets={filteredDatasets} stepSize={500000} calcType="add" hideLegend={false} hideRangeSelector={true} suggestedMin={0} />
      </div>

      <div className="flex flex-wrap mt-6 mb-6 pl-4 items-center">
        <p className="hidden md:block mr-3 text-sm font-medium text-gray-500">Filters</p>

        <fieldset aria-label="Filter Metrics">
          <RadioGroup className="grid grid-cols-2 gap-3 md:grid-cols-6">
            {filterOptions.map((filter) => (
              <Radio
                key={filter}
                value={filter}
                onClick={() => handleFilterChange(filter)}
                className={classNames(
                  selectedMetricFilters.includes(filter) ? "cursor-pointer focus:outline-none bg-red-200 opacity-70" : "bg-gray-200",
                  "flex items-center justify-center rounded-full text-xs font-semibold px-4 py-1 select-none border border-red-500"
                )}
              >
                {filter}
              </Radio>
            ))}
          </RadioGroup>
        </fieldset>
      </div>
      {/* <p className="pl-3 mb-3 font-light text-xs text-red-500">Most Recent Month</p> */}
      <EconomicImpactTable stats={stats} selectedSince={selectedSince} setSelectedSince={setSelectedSince} />
      <div className="mt-5 text-sm text-gray-700">Using industry averages. Click ‘Customize Inputs’ to use your own company data for more accurate results.</div>
    </div>
  );
}
