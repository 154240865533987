import { useContext, useState } from "react";
import { CompanyContext } from "../../contexts/companyContext";
import { useQuery } from "@tanstack/react-query";
import { Participant, Respondent, RiskInput, Survey, SurveyWithResults } from "../../types";
import useApi from "../../hooks/useApi";
import Container from "../../components/Container";
import EconomicImpact from "./EconomicImpact";
import {
  calculateEconomicRisk,
  calculateEmployeeTurnover,
  calculateMajorCrisis,
  calculatePercentageChange,
  calculateSequntialTask,
  calculateStressCategoryCounts,
  calculateWorkersCompensation,
  getLatestCompletedSurvey,
  percentageChange,
} from "../../hooks/helpers";

import { LockClosedIcon } from "@heroicons/react/16/solid";
import AtRiskDeepDive from "./AtRiskDeepDive";
import Button from "../../components/Button";
import { CogIcon } from "@heroicons/react/20/solid";
import CustomizeInputsModal from "./CustomizeInputsModal";

export default function Risk() {
  const companyContext = useContext(CompanyContext);
  const { getApiData } = useApi();
  const companyId = companyContext.companyId;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: riskInputs } = useQuery<RiskInput>({
    queryKey: ["riskInputs", companyId],
    queryFn: () => getApiData(`/company/info/risk/inputs`),
    enabled: !!companyId,
  });

  const { data: surveys } = useQuery<SurveyWithResults[]>({
    queryKey: ["surveysWithResults", companyId],
    queryFn: () => getApiData(`/company/surveys/results`),
    enabled: !!companyId,
    initialData: []
  });

  const latestCompletedSurvey = surveys ? getLatestCompletedSurvey(surveys) : null;

  if (!latestCompletedSurvey || !latestCompletedSurvey.results) {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="flex flex-col justify-center content-center items-center">
          <LockClosedIcon className="h-14 w-14 mb-4 bg-yellow-400 rounded-full p-4" />
          <h1 className="text-xl font-bold">No Economic Risk Impact - Yet</h1>
          <p className="mt-4 text-gray-500 text-center">Come back after your first assessment is complete</p>
        </div>
      </div>
    );
  }

  const riskCosts = calculateEconomicRisk(latestCompletedSurvey.results, riskInputs);

  return (
    <div>
      <h1 className="text-2xl font-bold">Economic Risk Impact</h1>
      <div>
        <Container title="" className="mt-3 border-2 border-red-500">
          <EconomicImpact
            economicRiskCosts={riskCosts}
            surveys={surveys}
            riskInputs={riskInputs}
          />
        </Container>

        <div className="mt-8">
          <div className="flex items-center mb-6 gap-8">
            <h1 className="text-2xl font-bold">Economic Risk Deep Dive</h1>
            <Button text={"Customize Inputs"} variant="secondary" icon={CogIcon} onClick={() => setIsModalOpen(true)} />
          </div>

          <AtRiskDeepDive
            surveyResults={latestCompletedSurvey.results}
            economicRiskCosts={riskCosts}
            riskInputs={riskInputs}
          />
        </div>

        <CustomizeInputsModal open={isModalOpen} setOpen={() => setIsModalOpen(false)} riskInputs={riskInputs} />
      </div>
    </div>
  );
}
