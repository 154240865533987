import colors from "tailwindcss/colors";
import { CompanyHealth, Dataset, Group, GroupResult, Respondent, StrataJobTitle, StrataJobTitleResult, Survey, SurveyResult, SurveyWithResults } from "../../types";
import { SI_VALUE_UPPER_CUTOFF, SI_VALUE_LOWER_CUTOFF, DISENGAGED_UPPER_CUTOFF, HIGH_YELLOW_UPPER_CUTOFF, HIGH_GREEN_UPPER_CUTOFF } from "../../constants";
import { calculateSum, round } from "../../hooks/helpers";

export function countRed(results: SurveyResult | GroupResult | StrataJobTitleResult) {
    return results.low_red_count + results.mid_red_count + results.high_red_count
}

export function countYellow(results: SurveyResult | GroupResult | StrataJobTitleResult) {
    return results.low_yellow_count + results.mid_yellow_count + results.high_yellow_count
}

export function countGreen(results: SurveyResult | GroupResult | StrataJobTitleResult) {
    return results.mid_green_count + results.high_green_count
}

export function getResponseRate(results: SurveyResult | GroupResult | StrataJobTitleResult) {
    return round((results.total_respondents / results.total_recipients) * 100, 2);
}

export function getPreviousCompletedSurvey(surveys: SurveyWithResults[]): SurveyWithResults | null {
  return surveys.filter((s) => new Date(s.end_date) < new Date() && s.results).sort((a, b) => new Date(b.end_date).getTime() - new Date(a.end_date).getTime())[1];
}

export function getPreviousSurvey(surveys: SurveyWithResults[], currentSurveyId: SurveyWithResults): SurveyWithResults | null {
  const sortedSurveys = surveys.slice().sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime());
  const index = sortedSurveys.findIndex(survey => survey.survey_id === currentSurveyId.survey_id);
  return index > 0 ? sortedSurveys[index - 1] : null;
}

export function getStressCategoryDatasets(
    surveys: SurveyWithResults[],
    showCustomGroups: boolean,
    selectedStrataJobTitleFilters: number[],
    selectedGroupFilters: number[]
): Dataset[] {

    if ((showCustomGroups && selectedGroupFilters.length <= 0) || (!showCustomGroups && selectedStrataJobTitleFilters.length <= 0)) {
        return [
            {
                label: "At-risk",
                data: surveys.map(survey => ({
                    value: countRed(survey.results!),
                    date: survey.start_date
                })),
                color: colors.red[400],
            },
            {
                label: "Impaired",
                data: surveys.map(survey => ({
                    value: countYellow(survey.results!),
                    date: survey.start_date
                })),
                color: colors.yellow[400],
            },
            {
                label: "Optimized",
                data: surveys.map(survey => ({
                    value: countGreen(survey.results!),
                    date: survey.start_date
                })),
                color: colors.green[400],
            },
            {
                label: "Disengaged",
                data: surveys.map(survey => ({
                    value: survey.results!.disengaged_count,
                    date: survey.start_date
                })),
                color: colors.gray[400],
            },
        ]
    } else {
        return [
            {
                label: "At-risk",
                data: surveys.map(survey => ({
                    value: calculateSum((showCustomGroups ?
                        survey.groupResults!.filter(gr => selectedGroupFilters.length > 0 ? selectedGroupFilters.includes(gr.group_id) : true) :
                        survey.strataJobTitleResults!.filter(gr => selectedStrataJobTitleFilters.length > 0 ? selectedStrataJobTitleFilters.includes(gr.strata_job_title_id) : true)).map(result => countRed(result))
                    ),
                    date: survey.start_date
                })),
                color: colors.red[400],
            },
            {
                label: "Impaired",
                data: surveys.map(survey => ({
                    value: calculateSum((showCustomGroups ?
                        survey.groupResults!.filter(gr => selectedGroupFilters.length > 0 ? selectedGroupFilters.includes(gr.group_id) : true) :
                        survey.strataJobTitleResults!.filter(gr => selectedStrataJobTitleFilters.length > 0 ? selectedStrataJobTitleFilters.includes(gr.strata_job_title_id) : true)).map(result => countYellow(result))
                    ),
                    date: survey.start_date
                })),
                color: colors.yellow[400],
            },
            {
                label: "Optimized",
                data: surveys.map(survey => ({
                    value: calculateSum((showCustomGroups ?
                        survey.groupResults!.filter(gr => selectedGroupFilters.length > 0 ? selectedGroupFilters.includes(gr.group_id) : true) :
                        survey.strataJobTitleResults!.filter(gr => selectedStrataJobTitleFilters.length > 0 ? selectedStrataJobTitleFilters.includes(gr.strata_job_title_id) : true)).map(result => countGreen(result))
                    ),
                    date: survey.start_date
                })),
                color: colors.green[400],
            },
            {
                label: "Disengaged",
                data: surveys.map(survey => ({
                    value: calculateSum((showCustomGroups ?
                        survey.groupResults!.filter(gr => selectedGroupFilters.length > 0 ? selectedGroupFilters.includes(gr.group_id) : true) :
                        survey.strataJobTitleResults!.filter(gr => selectedStrataJobTitleFilters.length > 0 ? selectedStrataJobTitleFilters.includes(gr.strata_job_title_id) : true)).map(result => result.disengaged_count)
                    ),
                    date: survey.start_date
                })),
                color: colors.gray[400],
            },
        ]
    }
}


export function calculateLatestCompletedStrataJobTitleCounts(strataJobTitles: StrataJobTitle[], latestRespondents: Respondent[]) {
    const latestCompletedStrataJobTitleCounts: {
        name: string;
        red: number;
        yellow: number;
        green: number;
        gray: number;
        taken: number;
    }[] = [];

    for (const strataJobTitle of strataJobTitles) {
        const latestRespondentsWithStrataJobTitle = latestRespondents.filter((res) => res.strata_job_title_id === strataJobTitle.strata_job_title_id);
        let redCount = 0;
        let yellowCount = 0;
        let greenCount = 0;
        let grayCount = 0;
        let takenCount = 0;

        for (const respondent of latestRespondentsWithStrataJobTitle) {
            takenCount++;
            const mentalHealthValue = parseFloat(respondent.mental_health_value);
            if (mentalHealthValue > SI_VALUE_UPPER_CUTOFF) {
                redCount++;
            } else if (mentalHealthValue > SI_VALUE_LOWER_CUTOFF) {
                yellowCount++;
            } else if (mentalHealthValue > DISENGAGED_UPPER_CUTOFF) {
                greenCount++;
            } else {
                grayCount++;
            }
        }
        latestCompletedStrataJobTitleCounts.push({
            name: strataJobTitle.strata_job_title_name,
            red: redCount,
            yellow: yellowCount,
            green: greenCount,
            gray: grayCount,
            taken: takenCount,
        });
    }

    return latestCompletedStrataJobTitleCounts;
}

export function calculateLatestCompletedGroupCounts(groups: Group[], latestRespondents: Respondent[]) {
    const latestCompletedGroupCounts: { name: string; red: number; yellow: number; green: number; gray: number; taken: number }[] = [];

    for (const group of groups) {
        const latestRespondentsInGroup = latestRespondents.filter((lr) => lr.group_ids && lr.group_ids.includes(group.group_id));
        let redCount = 0;
        let yellowCount = 0;
        let greenCount = 0;
        let grayCount = 0;
        let groupTakenCount = 0;

        latestRespondentsInGroup.forEach((r) => {
            groupTakenCount++;
            const mentalHealthValue = parseFloat(r.mental_health_value);
            if (mentalHealthValue > SI_VALUE_UPPER_CUTOFF) {
                redCount++;
            } else if (mentalHealthValue > SI_VALUE_LOWER_CUTOFF) {
                yellowCount++;
            } else if (mentalHealthValue > DISENGAGED_UPPER_CUTOFF) {
                greenCount++;
            } else {
                grayCount++;
            }
        });

        latestCompletedGroupCounts.push({
            name: group.group_name,
            red: redCount,
            yellow: yellowCount,
            green: greenCount,
            gray: grayCount,
            taken: groupTakenCount,
        });
    };

    return latestCompletedGroupCounts;
}

export function calculateStrataJobTitleImpairedTrendDatasets(strataJobTitles: StrataJobTitle[], completedSurveys: Survey[], respondents: Respondent[]) {
    let strataJobTitleImpairedTrendDatasets: Dataset[] = strataJobTitles.map((jobTitle) => {
        return { label: jobTitle.strata_job_title_name ?? "Not Found", data: [] };
    });

    // impaired counts for each month
    for (let i = 0; i < completedSurveys.length; i++) {
        const strataJobTitlesObject: any = {};
        const respondentsForSuvey = respondents.filter((r) => r.survey_id === completedSurveys[i].survey_id);

        for (const strataJobTitle of strataJobTitles) {
            const respondentsWithStrataJobTitle = respondentsForSuvey.filter((res) => res.strata_job_title_id === strataJobTitle.strata_job_title_id);
            let redCount = 0;
            let yellowCount = 0;
            respondentsWithStrataJobTitle.forEach((r) => {
                const mentalHealthValue = parseFloat(r.mental_health_value);
                if (mentalHealthValue > HIGH_YELLOW_UPPER_CUTOFF) {
                    redCount++;
                } else if (mentalHealthValue > HIGH_GREEN_UPPER_CUTOFF) {
                    yellowCount++;
                }
            });

            const dataset = strataJobTitleImpairedTrendDatasets.find((ds) => ds.label === strataJobTitle.strata_job_title_name);
            if (dataset) {
                dataset.data[i] = { value: redCount + yellowCount, date: new Date(completedSurveys[i].start_date) };
            }
        }

        Object.values(strataJobTitlesObject).forEach((jt: any) => {
            const dataset = strataJobTitleImpairedTrendDatasets.find((d) => d.label === jt.name);
            if (dataset) {
                dataset.data[i] = { value: jt.count, date: new Date(completedSurveys[i].start_date) };
            }
        });
    }

    return strataJobTitleImpairedTrendDatasets;
}

export function calculateGroupImpairedTrendDatasets(groups: Group[], completedSurveys: Survey[], respondents: Respondent[]) {
    let groupImpairedTrendDatasets: Dataset[] = groups.map((group) => {
        return { label: group.group_name ?? "Not Found", data: [] };
    });

    // impaired counts for each month
    for (let i = 0; i < completedSurveys.length; i++) {
        const groupsObject: any = {};
        const respondentsForSuvey = respondents.filter((r) => r.survey_id === completedSurveys[i].survey_id);
        groups.forEach((group) => {
            const respondentsInGroup = respondentsForSuvey.filter((lr) => lr.group_ids && lr.group_ids.includes(group.group_id));
            let redCount = 0;
            let yellowCount = 0;
            respondentsInGroup.forEach((r) => {
                const mentalHealthValue = parseFloat(r.mental_health_value);
                if (mentalHealthValue > HIGH_YELLOW_UPPER_CUTOFF) {
                    redCount++;
                } else if (mentalHealthValue > HIGH_GREEN_UPPER_CUTOFF) {
                    yellowCount++;
                }
            });

            const dataset = groupImpairedTrendDatasets.find((ds) => ds.label === group.group_name);
            if (dataset) {
                dataset.data[i] = { value: redCount + yellowCount, date: new Date(completedSurveys[i].start_date) };
            }
        });

        Object.values(groupsObject).forEach((g: any) => {
            const dataset = groupImpairedTrendDatasets.find((d) => d.label === g.name);
            if (dataset) {
                dataset.data[i] = { value: g.count, date: new Date(completedSurveys[i].start_date) };
            }
        });
    }

    return groupImpairedTrendDatasets;
}

export function calculateParticipationRates(surveyKeysWithGroup: any, showCustom: boolean) {
    // Create an object to store counts for each group
    const groupStats = surveyKeysWithGroup.reduce(
        (acc: { [x: string]: { completed: number; total: number } }, surveyKeyGroup: { group_name: string; is_taken: boolean; strata_job_title_name: string }) => {
            let group = showCustom ? surveyKeyGroup.group_name : surveyKeyGroup.strata_job_title_name;
            if (!group) {
                group = "Not Assigned";
            }
            if (!acc[group]) {
                acc[group] = {
                    total: 0,
                    completed: 0,
                };
            }
            acc[group].total += 1;
            if (surveyKeyGroup.is_taken) {
                acc[group].completed += 1;
            }
            return acc;
        },
        {}
    );

    // Calculate percentages for each group
    const results = Object.entries(groupStats).map(([group, stats]: any[]) => ({
        group,
        participationRate: ((stats.completed / stats.total) * 100).toFixed(0),
    }));

    return results;
}