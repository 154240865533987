import { CSSProperties } from "react";
import GaugeComponent from "react-gauge-component";
import colors from "tailwindcss/colors";
import GaugeLegend from "../Home/GaugeLegend";
import { SI_VALUE_LOWER_CUTOFF, SI_VALUE_UPPER_CUTOFF } from "../../constants";
import { ArrowTrendingUpIcon, ArrowTrendingDownIcon } from "@heroicons/react/24/solid";

function generateTooltip(description: string, implication: string): string {
  return `<ul>
            <li style="font-size: 15px"><b>${description}</b></li>
            </li style="font-size: 12px"><i>Implications: ${implication}</i></li>
          </ul>`;
}

const tooltipStyle: CSSProperties = {
  background: "rgba( 255, 255, 255, 0.85 )",
  boxShadow: "0 8px 12px 0 rgba( 31, 38, 31, 0.17 )",
  backdropFilter: "blur( 4px )",
  WebkitBackdropFilter: "blur( 4px )",
  borderRadius: "10px",
  textShadow: "none",
  color: colors.gray[600],
  border: "none",
  //maxWidth: "500px"
};

export default function SIValueGuage(props: { siValue: number; lastSIValue?: number | null }) {
  const { siValue, lastSIValue } = props;

  let changePercentage = 0;

  if (lastSIValue) {
    changePercentage = Math.round(((siValue - lastSIValue) / lastSIValue) * 100);
  }

  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="flex flex-col justify-center items-center w-full max-w-3xl">
        <div className="text-center">
          <span className="inline-block  text-[#000000] font-bold text-3xl">
            {siValue.toFixed(1)}
          </span>
        </div>
        <GaugeComponent
          type="semicircle"
          style={{ width: '100%', height: 'auto', maxHeight: '300px' }}
          arc={{
            padding: 0.02,
            cornerRadius: 1,
            gradient: true,
            subArcs: [
              {
                limit: 0,
                color: colors.red[400],
                showTick: false,
              },
              {
                limit: 25,
                color: colors.yellow[400],
                // showTick: true,
                tooltip: {
                  text: generateTooltip(
                    "High Risk Zone",
                    "Immediate attention required. Teams experiencing significant stress."
                  ),
                  style: tooltipStyle,
                },
              },
              {
                limit: 65,
                color: '#BFD239', // gradient color between yellow and green
                showTick: true,
                tooltip: {
                  text: generateTooltip(
                    "Moderate Risk Zone",
                    "Caution needed. Monitor team stress levels closely."
                  ),
                  style: tooltipStyle,
                },
              },
              {
                limit: 100,
                color: colors.green[400], 
                showTick: true,
                tooltip: {
                  text: generateTooltip(
                    "Optimal Zone",
                    "Teams operating at healthy stress levels with good performance."
                  ),
                  style: tooltipStyle,
                },
              },
            ],
          }}
          pointer={{
            type: "needle",
            color: "#6b7280", // gray-500
            elastic: true,
            length: 0.8, // Adjust this value between 0 and 1
          }}
          labels={{
            valueLabel: {
              formatTextValue: (value) => `${value}`,
              hide: true,
              style: { 
                textShadow: "none", 
                fill: "#6b7280",
                background: "white",
                padding: "4px 8px",
                borderRadius: "6px",
                boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
                border: "1px solid #e5e7eb"
              },
              matchColorWithArc: false,
            },
            
            tickLabels: {
              type: "outer",
              defaultTickValueConfig: {
                formatTextValue: (value) => `${value}`,
                style: {
                  fontSize: "12px",
                  fill: "#6b7280",
                  fontWeight: "bold",
                },
              },
            },
            
          }}
          value={siValue}
          minValue={0}
          maxValue={100}
          
        />
        {(lastSIValue && !Number.isNaN(lastSIValue)) && (
          <div className="text-center w-full text-gray-500 text-sm mt-2">
            Since last month:{" "}
            {changePercentage >= 0 ? (
              <span className="text-green-500 inline-flex items-center">
                +{Math.abs(siValue - lastSIValue).toFixed(1)} points 
                <ArrowTrendingUpIcon className="h-5 w-5 ml-1" />
              </span>
            ) : (
              <span className="text-red-500 inline-flex items-center">
                -{Math.abs(siValue - lastSIValue).toFixed(1)} points 
                <ArrowTrendingDownIcon className="h-5 w-5 ml-1" />
              </span>
            )}
          </div>
        )}
        {/* <div className="mt-4 w-full">
          <GaugeLegend />
        </div> */}
      </div>
    </div>
  );
}
