/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useContext } from "react";
import { Dialog, DialogPanel, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { AuthContext, useAuth } from "../contexts/authContext";
import Button from "./Button";
import { CompanyContext } from "../contexts/companyContext";
import { useNotifications } from "../contexts/notificationContext";
import useApi from "../hooks/useApi";

export default function HRSystemRequestModal(props: {
  open: boolean;
  setOpen: (value: boolean) => void;
}) {
  const { open, setOpen } = props;
  const [provider, setProvider] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const auth = useAuth();
  const companyContext = useContext(CompanyContext);
  const notifications = useNotifications();
  const { postApiData } = useApi();

  async function sendMessage() {
    if (provider) {
      setSubmitting(true);
      try {
        const { body } = await postApiData("/company/email/send", {
          subject: `Company Id: ${companyContext.companyId} - HR System Request`,
          message: `${auth.userAttributes.given_name} ${auth.userAttributes.family_name} has requested integration of their HR provider: ${provider}`,
        })
        if (body.MessageId) {
          notifications.addNotification("Request Submitted", "Your integration request was proccessed successfully")
          setOpen(false);
        } else {
          console.error(body);
          setError(body);
        }
        setSubmitting(false);
      } catch (err: any) {
        console.error(err);
        setSubmitting(false);
      }
    } else {
      setError("Please fill out provider");
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto max-w-4xl mx-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8  sm:max-w-6xl sm:p-6">
                <XMarkIcon
                  className="absolute top-2 right-2 text-blue-500 h-5 w-5 cursor-pointer hover:text-blue-900"
                  onClick={() => setOpen(false)}
                />
                <div className="relative bg-white">
                  <div className="absolute inset-0">
                    <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
                  </div>
                  <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
                    <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
                      <div className="max-w-lg mx-auto">
                        <h2 className="text-3xl font-extrabold tracking-tight text-blue-600 sm:text-3xl">
                          Strata Intel Support
                        </h2>
                        <h2 className="text-xl font-extrabold tracking-tight text-gray-900 sm:text-xl">
                          Request to Integrate with HR System
                        </h2>
                        <p className="mt-3 text-lg leading-6 text-gray-500">
                          Let us know who your HR system provider is to request
                          automatic participant syncing
                        </p>
                      </div>
                    </div>
                    <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
                      <div className="max-w-lg mx-auto lg:max-w-none">
                        <div className="grid grid-cols-1 gap-y-6">
                          <div>
                            <label htmlFor="provider" className="sr-only">
                              HR Provider
                            </label>
                            <input
                              type="text"
                              name="provider"
                              id="provider"
                              className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded"
                              placeholder="HR Provider"
                              onChange={(e) => setProvider(e.target.value)}
                              value={provider}
                            />
                          </div>
                          {error && (
                            <span className="text-sm text-red-500">
                              {error}
                            </span>
                          )}
                          <div>
                            <Button
                              type="button"
                              text="Request Automation"
                              onClick={() => sendMessage()}
                              submitting={submitting}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
