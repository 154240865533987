import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useContext, useState } from "react";
import Button from "../../components/Button";
import { useFormik } from "formik";
import useApi from "../../hooks/useApi";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { RiskInput } from "../../types";
import { CompanyContext } from "../../contexts/companyContext";

interface CustomizeInputsModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  riskInputs: RiskInput | undefined;
}

export default function CustomizeInputsModal({ open, setOpen, riskInputs }: CustomizeInputsModalProps) {
  const { postApiData, getApiData } = useApi();
  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState<string | null>(null);
  const companyContext = useContext(CompanyContext);
  const queryClient = useQueryClient();

  const formik = useFormik({
    initialValues: {
      averageSalary: riskInputs?.average_salary || 35000,
      replacementCostPercentage: riskInputs?.replacement_cost_percentage || 75,
      totalAssumedRevenue: riskInputs?.total_revenue || 10000000,
      badDebtPercentage: riskInputs?.bad_debt_percentage || 0.5,
      workersCompensationPayout: riskInputs?.average_workers_comp_payout || 41757,
      averageSettlement: riskInputs?.average_settlement || 400000,
    },
    validate: (values) => {
      const errors: { [key: string]: string } = {};

      if (!values.averageSalary) {
        errors.averageSalary = "Required";
      } else if (values.averageSalary < 0) {
        errors.averageSalary = "Must be greater than 0";
      }

      if (!values.replacementCostPercentage) {
        errors.replacementCostPercentage = "Required";
      } else if (Number(values.replacementCostPercentage) < 0) {
        errors.replacementCostPercentage = "Must be greater than 0";
      }

      if (!values.totalAssumedRevenue) {
        errors.totalAssumedRevenue = "Required";
      }

      if (!values.badDebtPercentage) {
        errors.badDebtPercentage = "Required";
      } else if (Number(values.badDebtPercentage) < 0 || Number(values.badDebtPercentage) > 100) {
        errors.badDebtPercentage = "Must be between 0 and 100";
      }

      if (!values.workersCompensationPayout) {
        errors.workersCompensationPayout = "Required";
      }

      if (!values.averageSettlement) {
        errors.averageSettlement = "Required";
      }

      return errors;
    },
    onSubmit: async (values) => {
      setIsSaving(true);
      setSaveError(null);

      try {
        await postApiData("/company/info/risk/inputs", values);
        queryClient.invalidateQueries({queryKey: ["riskInputs", companyContext.companyId]})
        setOpen(false);
      } catch (error) {
        setSaveError(error instanceof Error ? error.message : "Failed to save changes");
      } finally {
        setIsSaving(false);
      }
    },
  });

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-2xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span className="sr-only">Close</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>

            <div className="">
              <div className="mt-3 text-center sm:mt-0 sm:text-left">
                <DialogTitle as="h3" className="text-xl font-semibold leading-6 text-gray-900 mb-3">
                  Customize Economic Risk Inputs
                </DialogTitle>

                <div className="bg-blue-50 border border-blue-200 rounded-md p-4 mb-6">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <svg className="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor">
                        <path
                          fillRule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-blue-700">Adjusting these values from industry averages to your organization's specific circumstances will update the economic risk calculations.</p>
                    </div>
                  </div>
                </div>

                <div className="space-y-6">
                  {/* Turnover Cost Section */}
                  <div className="border p-4 rounded-lg">
                    <h4 className="text-base font-semibold text-gray-900 mb-4">Turnover Cost</h4>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label htmlFor="averageSalary" className="block text-sm font-medium text-gray-700 mb-1">
                          Average Salary ($)
                        </label>
                        <input
                          id="averageSalary"
                          type="number"
                          value={formik.values.averageSalary}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        />
                        {formik.touched.averageSalary && formik.errors.averageSalary && <p className="mt-1 text-sm text-red-600">{formik.errors.averageSalary}</p>}
                      </div>
                      <div>
                        <label htmlFor="replacementCost" className="block text-sm font-medium text-gray-700 mb-1">
                          Replacement Cost (%)
                        </label>
                        <input
                          id="replacementCostPercentage"
                          type="number"
                          min="0"
                          max="100"
                          value={formik.values.replacementCostPercentage}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        />
                        {formik.touched.replacementCostPercentage && formik.errors.replacementCostPercentage && <p className="mt-1 text-sm text-red-600">{formik.errors.replacementCostPercentage}</p>}
                      </div>
                    </div>
                  </div>

                  {/* Sequential Task Error Section */}
                  <div className="p-4 rounded-lg border">
                    <h4 className="text-base font-semibold text-gray-900 mb-4">Sequential Task Error</h4>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label htmlFor="totalAssumedRevenue" className="block text-sm font-medium text-gray-700 mb-1">
                          Total Revenue ($)
                        </label>
                        <input
                          id="totalAssumedRevenue"
                          type="number"
                          value={formik.values.totalAssumedRevenue}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        />
                        {formik.touched.totalAssumedRevenue && formik.errors.totalAssumedRevenue && <p className="mt-1 text-sm text-red-600">{formik.errors.totalAssumedRevenue}</p>}
                      </div>
                      <div>
                        <label htmlFor="badDebtPercentage" className="block text-sm font-medium text-gray-700 mb-1">
                          Bad Debt (%)
                        </label>
                        <input
                          id="badDebtPercentage"
                          type="number"
                          min="0"
                          max="100"
                          step="0.1"
                          value={formik.values.badDebtPercentage}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        />
                        {formik.touched.badDebtPercentage && formik.errors.badDebtPercentage && <p className="mt-1 text-sm text-red-600">{formik.errors.badDebtPercentage}</p>}
                      </div>
                    </div>
                  </div>

                  {/* Workers Compensation Section */}
                  <div className="border p-4 rounded-lg">
                    <h4 className="text-base font-semibold text-gray-900 mb-4">Workers Compensation</h4>
                    <div>
                      <label htmlFor="workersCompensationPayout" className="block text-sm font-medium text-gray-700 mb-1">
                        Average Payout ($)
                      </label>
                      <input
                        id="workersCompensationPayout"
                        type="number"
                        value={formik.values.workersCompensationPayout}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      />
                      {formik.touched.workersCompensationPayout && formik.errors.workersCompensationPayout && <p className="mt-1 text-sm text-red-600">{formik.errors.workersCompensationPayout}</p>}
                    </div>
                  </div>

                  {/* Major Crisis Section */}
                  {/* <div className="border p-4 rounded-lg">
                    <h4 className="text-base font-semibold text-gray-900 mb-4">Major Crisis</h4>
                    <div>
                      <label htmlFor="averageSettlement" className="block text-sm font-medium text-gray-700 mb-1">
                        Average Settlement ($)
                      </label>
                      <input
                        id="averageSettlement"
                        type="number"
                        value={formik.values.averageSettlement}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      />
                      {formik.touched.averageSettlement && formik.errors.averageSettlement && <p className="mt-1 text-sm text-red-600">{formik.errors.averageSettlement}</p>}
                    </div>
                  </div> */}
                </div>
              </div>

              {saveError && (
                <div className="mt-4 p-4 bg-red-50 border border-red-200 rounded-md">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-red-700">{saveError}</p>
                    </div>
                  </div>
                </div>
              )}

              <form onSubmit={formik.handleSubmit}>
                <div className="mt-7 flex justify-end space-x-3">
                  <Button text="Cancel" variant="secondary" onClick={() => setOpen(false)} type="button" disabled={isSaving} />
                  <Button text={isSaving ? "Saving..." : "Save"} type="submit" disabled={!formik.isValid || isSaving} />
                </div>
              </form>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
