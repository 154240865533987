import { useContext, useEffect, useState } from "react";
import { Respondent, Survey, Group, StrataJobTitle, CompanyEvent, Company, Dataset, CompanySurveyResult, SurveyResult } from "../../types";
import Container from "../../components/Container";
import TrendChart from "../../components/TrendChart";
import { ChevronDownIcon } from "@heroicons/react/16/solid";
import { displayMonth } from "../../hooks/helpers";
import { CheckIcon } from "@heroicons/react/24/outline";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/react";
import CompanyDistributions from "./CompanyDistributions";
import OverviewHeader from "./OverviewHeader";
import { CompanyContext } from "../../contexts/companyContext";

export default function OverviewReport(props: { companySurveyResults: CompanySurveyResult[] }) {
  const { companySurveyResults } = props;

  const { setShowAllAccounts } = useContext(CompanyContext);

  const [selectedMonth, setSelectedMonth] = useState<{ month: number; year: number } | null>(null);

  const surveyResultsForSelectedMonth = selectedMonth
    ? companySurveyResults
        .map((c) => c.surveys.find((s) => s.results && new Date(s.start_date).getMonth() === selectedMonth.month && new Date(s.start_date).getFullYear() === selectedMonth.year))
        .filter((result): result is Survey & { results: SurveyResult } => result !== undefined)
    : [];

  const previousMonth = selectedMonth
    ? selectedMonth.month > 1
      ? { month: selectedMonth?.month - 1, year: selectedMonth.year }
      : { month: 12, year: selectedMonth.year - 1 }
    : null;

  const surveyResultsForPreviousMonth = previousMonth
    ? companySurveyResults
        .map((c) => c.surveys.find((s) => s.results && new Date(s.start_date).getMonth() === previousMonth.month && new Date(s.start_date).getFullYear() === previousMonth.year))
        .filter((result): result is Survey & { results: SurveyResult } => result !== undefined)
    : [];

  const allSurveyResults = companySurveyResults.flatMap((c) => c.surveys).filter(s => s.results);

  const monthsWithCompletedSurveys = Array.from(
    new Map(
      allSurveyResults.map((s) => {
        const month = new Date(s.start_date).getMonth();
        const year = new Date(s.start_date).getFullYear();
        return [`${month}-${year}`, { month, year }];
      })
    ).values()
  ).sort((a, b) => b.year - a.year || b.month - a.month);

  useEffect(() => {
    setSelectedMonth(monthsWithCompletedSurveys[0]);
  }, [companySurveyResults]);

  useEffect(() => {
    setShowAllAccounts(true); //ensure that they are on all accounts tab
  }, []);

  return (
    <div id="report" className="">
      <div className="mb-6">
        {selectedMonth && (
          <div className="flex justify-between items-center">
            <div>
              <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">Account Results Overview</h2>
              <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">An aggregate report of all your accounts</p>
            </div>

            <div className="flex items-center gap-4">
              <Menu as="div" className="relative inline-block text-left border-2 bg-white rounded-lg hover:bg-stone-50">
                <div className="">
                  <MenuButton className="inline-flex w-full justify-center items-center gap-x-2 text-xl sm:text-2xl font-bold tracking-tight text-gray-900 px-2 py-1 rounded-md">
                    {displayMonth(selectedMonth.month)} {selectedMonth.year}
                    <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400" />
                  </MenuButton>
                </div>

                <MenuItems
                  transition
                  className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  {monthsWithCompletedSurveys.map((d, index) => (
                    <div onClick={() => setSelectedMonth(d)} key={index}>
                      <MenuItem>
                        <div
                          className={`flex items-center px-4 py-2 ${
                            selectedMonth.month === d.month && selectedMonth.year === d.year ? "bg-gray-100 cursor-default" : "hover:bg-gray-100 cursor-pointer"
                          }`}
                        >
                          <div className={`block text-sm text-gray-700`}>
                            {displayMonth(d.month - 1)} {d.year}
                          </div>
                          {selectedMonth.month === d.month && selectedMonth.year === d.year && <CheckIcon className="size-4 text-gray-400 ml-1" />}
                        </div>
                      </MenuItem>
                    </div>
                  ))}
                </MenuItems>
              </Menu>
            </div>
          </div>
        )}
      </div>

      <div className="border mb-12"></div>

      <OverviewHeader companies={companySurveyResults} selectedMonthSurveys={surveyResultsForSelectedMonth} previousMonthSurveys={surveyResultsForPreviousMonth} />

      <div className="mb-4 mt-12">
        <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">Account Distributions</h2>
      </div>
      <Container className="col-span-3 h-96 mb-4">
        <CompanyDistributions companies={companySurveyResults} surveys={surveyResultsForSelectedMonth} />
      </Container>

      <div className="mb-4 mt-12">
        <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">SI Value Over Time</h2>
      </div>
      <Container className={`w-full h-96 relative`}>
        <TrendChart
          datasets={companySurveyResults.map((c) => ({
            label: c.company_name,
            data: allSurveyResults
              .filter((s) => s.company_id === c.company_id)
              .map((s) => ({
                value: parseFloat(s.si_value ?? "0"),
                date: s.start_date,
              })),
          }))}
          suggestedMax={100}
          suggestedMin={0}
          stepSize={10}
          calcType="average"
          yAxisLabel="SI Value"
          showCompanyEvents={false}
        />
      </Container>

      <div className="mb-4 mt-12">
        <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">Response Rate Over Time</h2>
      </div>
      <Container className={`w-full h-96 relative`}>
        <TrendChart
          datasets={companySurveyResults.map((c) => ({
            label: c.company_name,
            data: allSurveyResults
              .filter((s) => s.company_id === c.company_id)
              .map((s) => ({
                value: parseFloat(s.response_rate ?? "0") / 100,
                date: s.start_date,
              })),
          }))}
          suggestedMax={100}
          suggestedMin={0}
          calcType="average"
          displayType="percent"
          yAxisLabel="Response Rate"
          showCompanyEvents={false}
        />
      </Container>
    </div>
  );
}
