import { ReactNode } from "react";
import Tooltip from "./Tooltip";

export default function Container(props: {
  title?: string;
  tooltip?: string;
  className?: string;
  children: ReactNode;
}) {
  const { title, tooltip, className, children } = props;
  return (
    <div
      className={`bg-white shadow border rounded-md px-6 py-5 mx-auto max-w-7xl sm:px-6 ${className}`}
    >
      {title && (
        <div className="flex items-center gap-1.5 mb-4">
          <h3 className="text-md font-medium text-gray-900">{title}</h3>
          {tooltip && (
            <span className="flex items-center">
              <Tooltip message={tooltip} position="above" />
            </span>
          )}
        </div>
      )}
      {children}
    </div>
  );
}
