import { useEffect, useState } from "react";
import Button from "../../components/Button";
import Divider from "../../components/Divider";
import { EnvelopeIcon, ExclamationCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import ParticipantAutomationModal from "./ParticipantAutomation";
import SchedulePage from "./Schedule";
import CustomEmailSetupModal from "./CustomEmailSetupModal";
import { useSearchParams } from "react-router-dom";
import useApi from "../../hooks/useApi";
import { useNotifications } from "../../contexts/notificationContext";
import { useAuth } from "../../contexts/authContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EmailIdentity, RiskInput } from "../../types";
import { useCompany } from "../../contexts/companyContext";
import Alert from "../../components/Alert";
import colors from "tailwindcss/colors";
import MeatballMenu from "../../components/MeatballMenu";
import { ConfirmModalType, useConfirmationModal } from "../../contexts/confirmationModalContext";

export default function Assessments() {
  const [openCustomEmailModal, setOpenCustomEmailModal] = useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const { getApiData, postApiData, deleteApiData } = useApi();
  const notifications = useNotifications();
  const auth = useAuth();
  const { companyId } = useCompany();
  const confirmation = useConfirmationModal();
  const queryClient = useQueryClient();

  const verificationKey = searchParams.get("verificationKey");

  async function verifyEmailIdentity(verificationKey: string) {
    const { response, body } = await postApiData("/company/email/verify-identity", {
      verificationKey,
    });

    if (response.ok) {
      const email = body.verifiedEmail;
      await queryClient.invalidateQueries({ queryKey: ["emailIdentity", companyId] });
      notifications.addNotification("Email Verified!", `Your email address has been successfully verified, the assessment emails will now come from ${email}`);
    } else {
      notifications.addNotification("Error", "An error occurred verifying the email address, please try again later", "error");
    }
  }

  async function deleteEmailIdentity() {
    const { response } = await deleteApiData("/company/email/identity");

    if (response.ok) {
      await queryClient.resetQueries({ queryKey: ["emailIdentity", companyId] });
      notifications.addNotification("Success!", `Your custom email address has been removed`);
    } else {
      notifications.addNotification("Error", "An error occurred verifying the email address, please try again later", "error");
    }
  }

  const { data: emailIdentity } = useQuery<EmailIdentity>({
    queryKey: ["emailIdentity", companyId],
    queryFn: () => getApiData(`/company/email/identity`),
    enabled: !!companyId,
  });


  console.log(emailIdentity)
  useEffect(() => {
    if (verificationKey && auth.sessionInfo?.idToken) {
      verifyEmailIdentity(verificationKey);
    }
  }, [verificationKey, auth]);

  return (
    <div className="">
      <CustomEmailSetupModal open={openCustomEmailModal} setOpen={setOpenCustomEmailModal} />
      <div className="border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <div className="px-4 sm:px-0">
              <h3 className="text-sm font-medium leading-6 text-gray-900">Custom Email</h3>
              <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Set up the email you want the assessment invites to be sent from</p>
            </div>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {emailIdentity && emailIdentity.is_verified ? (
                <Alert
                  title="Enabled"
                  message={<div>All assessment emails are being sent from <span className="underline">{emailIdentity.email}</span></div>}
                  type="Success"
                  meatballMenuItems={[
                    {
                      name: "Remove Custom Email",
                      icon: TrashIcon,
                      color: colors.red[500],
                      onClick: async () =>
                        await confirmation({
                          title: "Are you sure?",
                          message: "Are you sure you want to remove this email? Future assessment emails will come from admin@strataintel.com",
                          type: ConfirmModalType.danger,
                          onConfirm: async () => await deleteEmailIdentity(),
                        }),
                    },
                  ]}
                />
              ) : emailIdentity && !emailIdentity.is_verified ? (
                <Alert
                  title="Waiting for verification"
                  message={`Please check the inbox of ${emailIdentity.email} for an email containing a verification link. Click the link to verify your email and begin sending assessment emails from your address.`}
                  type="Warning"
                  icon={ExclamationCircleIcon}
                  meatballMenuItems={[
                    {
                      name: "Remove Custom Email",
                      icon: TrashIcon,
                      color: colors.red[500],
                      onClick: async () =>
                        await confirmation({
                          title: "Are you sure?",
                          message: "Are you sure you want to remove this email? Future assessment emails will come from admin@strataintel.com",
                          type: ConfirmModalType.danger,
                          onConfirm: async () => await deleteEmailIdentity(),
                        }),
                    },
                  ]}
                />
              ) : (
                <Button text="Set-up" icon={EnvelopeIcon} onClick={() => setOpenCustomEmailModal(true)} />
              )}
            </dd>
          </div>
          <Divider heading={""} />
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <div className="px-4 sm:px-0">
              <h3 className="text-sm font-medium leading-6 text-gray-900">Assessment Schedule</h3>
              <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Choose when you would like the monthly well-being assessment to be sent</p>
            </div>
            <div className="col-span-2">
              <SchedulePage />
            </div>
          </div>
          <Divider heading={""} />
        </dl>
      </div>
    </div>
  );
}
