import { useAuth } from "../contexts/authContext";
import { useCompany } from "../contexts/companyContext";

const useApi = () => {
  const { sessionInfo, refreshAuth } = useAuth();
  const { companyId, setCompanyId } = useCompany();

  async function callAPI(
    endpoint: string,
    method: "GET" | "POST" | "PUT" | "DELETE",
    requestBody: any | null,
    retryCount: number = 0
  ): Promise<{ response: Response; body: any }> {
    const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionInfo?.idToken.toString() ?? "",
        "Company-Id": companyId ? companyId.toString() : "",
      },
      body: requestBody ? JSON.stringify(requestBody) : undefined,
    });

    let body: any = null;
    try {
      body = await response.json();

      // Retry if token expired
      if (body.name === "TokenExpiredError") {
        if (retryCount <= 1) {
          await refreshAuth();
          return callAPI(endpoint, method, requestBody, retryCount + 1);
        }
      }
    } catch (err) {}

    return { response, body };
  }

  const getApiData = async (endpoint: string) => {
    const { response, body } = await callAPI(endpoint, "GET", null);

    if (response.status === 401 && body?.type === "CompanyAccessDenied") {
      setCompanyId(null);
    }

    if (!response.ok) {
      const error = new Error("Network response was not ok");
      (error as any).response = response;
      throw error;
    }
    return deepParseDecimals(body) ?? null;
  };

  const postApiData = async (endpoint: string, requestBody: any) => await callAPI(endpoint, "POST", requestBody);
  const putApiData = async (endpoint: string, requestBody: any) => await callAPI(endpoint, "PUT", requestBody);
  const deleteApiData = async (endpoint: string) => await callAPI(endpoint, "DELETE", null);

  return { getApiData, postApiData, putApiData, deleteApiData };
};

export default useApi;

// Utility function to recursively parse decimal strings into numbers
const deepParseDecimals = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(deepParseDecimals);
  } else if (obj !== null && typeof obj === "object") {
    return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, shouldParseToDecimal(value) ? parseFloat(value as string) : deepParseDecimals(value)]));
  }
  return obj;
};

// Determines if a value should be parsed to a decimal
const shouldParseToDecimal = (value: any) => typeof value === "string" && /^\d+\.\d+$/.test(value);
