import { useQuery } from "@tanstack/react-query";
import useApi from "../../hooks/useApi";
import { CompanySurveyResult } from "../../types";
import { useAuth } from "../../contexts/authContext";
import OrganizationReport from "./OverviewReport";

export default function OverviewResults() {
  const { getApiData } = useApi();
  const auth = useAuth();

  const cogntioUserId = auth.userAttributes.sub;

  // Get all surveys for every company that a user is a part of
  const { data: companySurveyResults } = useQuery<CompanySurveyResult[]>({
    queryKey: ["companySurveyResults", cogntioUserId],
    queryFn: async () => getApiData(`/user/surveys/results`),
    initialData: [],
  });

  return (
    <div>
      <OrganizationReport companySurveyResults={companySurveyResults} />
    </div>
  );
}
