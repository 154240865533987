import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import useApi from "../../hooks/useApi";
import { CompanyContext } from "../../contexts/companyContext";
import { Survey, Respondent, Group, StrataJobTitle, CompanyEvent, SurveyKey, SurveyWithResults } from "../../types";
import Report from "./Report";
import { LockClosedIcon } from "@heroicons/react/16/solid";
import Spinner from "../../components/Spinner";
import { getLatestCompletedSurvey } from "../../hooks/helpers";

export default function Results() {
  const companyContext = useContext(CompanyContext);
  const { getApiData } = useApi();
  const location = useLocation();
  const navigate = useNavigate();
  const surveyIdPath = parseInt(location.pathname.replace("/assessments/", ""));
  const [surveyId, setSurveyId] = useState<number | null>(null);

  const { data: surveys, isSuccess: surveysAreReturned } = useQuery<SurveyWithResults[]>({
    queryKey: ["surveysWithResults", companyContext.companyId],
    queryFn: async () => getApiData(`/company/surveys/results`),
  });

  useEffect(() => {
    if (surveysAreReturned) {
      if (!isNaN(surveyIdPath)) {
        setSurveyId(surveyIdPath);
      } else {
        const latestCompletedSurvey = getLatestCompletedSurvey(surveys);
        if (latestCompletedSurvey) {
          setSurveyId(latestCompletedSurvey.survey_id ?? null);
        }
      }

      if (surveyId && !surveys.find((s) => s.survey_id === surveyId)) {
        navigate("/home");
      }
    }
  }, [surveys, surveysAreReturned, location]);


  if(!surveysAreReturned) {
    return <Spinner />
  }

  if (!surveyId) {
    return (
      <div>
        <div className="">
          <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">Mental Health Breakdown</h2>
        </div>
        <div className="flex items-center justify-center h-96">
          <div className="flex flex-col justify-center content-center items-center">
            <LockClosedIcon className="h-14 w-14 mb-4 bg-yellow-400 rounded-full p-4" />
            <h1 className="text-xl font-bold">No Breakdown Results - Yet</h1>
            <p className="mt-4 text-gray-500 text-center">Come back after your first assessment is complete</p>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Report
          surveyId={surveyId}
          surveys={surveys}
        />
      </div>
    );
  }
}
