import Button from "../../components/Button";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router";
import { useContext, useState } from "react";
import { AuthContext, useAuth } from "../../contexts/authContext";
import Logo from "../../assets/Logo.png";

export default function ForgotPassword() {
  const [codeSent, setCodeSent] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();

  return (
    <div className="h-screen bg-gray-50">
      <div className="min-h-full flex flex-col py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="mx-auto h-12 w-auto"
              src={Logo}
              alt="Strata Intel"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Password Reset
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              Reset Your Password
            </p>
          </div>
          <Formik
            initialValues={{
              email: "",
              code: "",
              newPassword: "",
              confirmPassword: "",
              all: "",
            }}
            validate={(values) => {
              const errors: any = {};
              if (!values.email) {
                errors.email = "Email is required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              if (codeSent) {
                if (!values.code) {
                  errors.code = "A verification code is required";
                }
                if (!values.newPassword) {
                  errors.newPassword = "A new password is required";
                }
                if (!values.confirmPassword) {
                  errors.confirmPassword = "Confirmation password is required";
                } else if (values.newPassword !== values.confirmPassword) {
                  errors.confirmPassword = "Passwords do not match";
                }
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              setSubmitting(true);
              try {
                if (!codeSent) {
                  await auth.sendResetPasswordCode(values.email);
                  setCodeSent(true);
                } else {
                  await auth.resetPassword(
                    values.code,
                    values.email,
                    values.newPassword
                  );
                  alert("Password was changed successfully");
                  navigate("/login");
                }
              } catch (err: any) {
                setErrors({ all: err.message });
              }
              setSubmitting(false);
            }}
          >
            {({ isSubmitting, setFieldValue }) => (
              <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded sm:px-10">
                  <Form className="space-y-6">
                    <div className="">
                      <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="email">
                        Email
                      </label>
                      <Field
                        disabled={codeSent}
                        type="text"
                        name="email"
                        className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
                          codeSent && "opacity-50"
                        }`}
                        placeholder="Email"
                      />
                      <ErrorMessage
                        className="text-red-500 text-xs"
                        name="email"
                        component="div"
                      />
                      {codeSent && (
                        <div>
                          <span className="text-sm">
                            The verification code has been sent to your email
                          </span>
                          <div className="mt-5">
                            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="code">
                              Code
                            </label>
                            <Field
                              type="text"
                              name="code"
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              placeholder="Code"
                            />
                            <ErrorMessage
                              className="text-red-500 text-xs"
                              name="code"
                              component="div"
                            />
                          </div>
                          <div className="mt-5">
                            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="newPassword">
                              New Password
                            </label>
                            <Field
                              type="password"
                              name="newPassword"
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              placeholder="New Password"
                            />
                            <ErrorMessage
                              className="text-red-500 text-xs"
                              name="newPassword"
                              component="div"
                            />
                          </div>
                          <div className="mt-5">
                            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="confirmPassword">
                              Confirm Password
                            </label>
                            <Field
                              type="password"
                              name="confirmPassword"
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              placeholder="Confirm Password"
                            />
                            <ErrorMessage
                              className="text-red-500 text-xs"
                              name="confirmPassword"
                              component="div"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <ErrorMessage
                      className="text-red-500 text-sm mt-5"
                      name="all"
                      component="div"
                    />
                    <div className="flex items-baseline justify-between mt-7">
                      <Button
                        text={"Back"}
                        onClick={() => navigate("/login")}
                      />
                      <Button
                        text={codeSent ? "Reset Password" : "Send Code"}
                        type="submit"
                        submitting={isSubmitting}
                      />
                    </div>
                  </Form>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
