import { CheckIcon } from "@heroicons/react/24/outline";
import { AuthContext } from "../../contexts/authContext";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Company, Participant, PaymentInfo, Schedule } from "../../types";
import { CompanyContext } from "../../contexts/companyContext";
import useApi from "../../hooks/useApi";

export default function PreSurveySteps() {
  const companyContext = useContext(CompanyContext);
  const { getApiData } = useApi();

  const [hasSchedule, setHasSchedule] = useState<boolean>(false);
  const [hasUsers, setHasUsers] = useState<boolean>(false);
  const [hasPayment, setHasPayment] = useState<boolean>(false);

  const { data: company } = useQuery<Company>({
    queryKey: ["company", companyContext.companyId],
    queryFn: () => getApiData(`/company/info`),
  });

  const { data: schedule } = useQuery<Schedule>({
    queryKey: ["schedule", companyContext.companyId],
    queryFn: () => getApiData(`/company/schedule`),
  });

  const { data: users } = useQuery<Participant[]>({
    queryKey: ["users", companyContext.companyId],
    queryFn: () => getApiData(`/company/users`),
    initialData: [],
  });

  const { data: payment } = useQuery<PaymentInfo>({
    queryKey: ["payment", companyContext.companyId],
    queryFn: () => getApiData(`/company/payment/info`),
  });

  useEffect(() => {
    if (schedule) {
      setHasSchedule(true);
    } else {
      setHasSchedule(false);
    }
    if (users.length > 0) {
      setHasUsers(true);
    } else {
      setHasUsers(false)
    }
    if (payment && payment.stripe_subscription_status === "active") {
      setHasPayment(true);
    } else {
      setHasPayment(false)
    }
  }, [schedule, users, payment]);

  let steps = [
    {
      id: "01",
      name: "Set Assessment Schedule",
      href: "/settings/assessments",
      completed: hasSchedule,
    },
    {
      id: "02",
      name: "Set Up Payment",
      href: "/settings/subscription",
      completed: hasPayment,
    },
    {
      id: "03",
      name: "Configure Assessment Participants",
      href: "/participants",
      completed: hasUsers,
    },
  ];

  if (!company?.is_billing) {
    steps = steps.filter((step) => step.id !== "02");
  }
  return (
    <div className="max-w-7xl mb-6">
      <div className="flex flex-col items-start w-full">
        <nav aria-label="Progress" className="w-full mb-2">
          <ol  className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0 bg-white">
            {steps.map((step, stepIdx) => (
              <li key={step.name} className="relative md:flex md:flex-1">
                <Link to={step.href} className="group flex items-center">
                  <span className="flex items-center px-6 py-4 text-sm font-medium">
                    {step.completed ? (
                      <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-green-600">
                        <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </span>
                    ) : (
                      <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-blue-700">
                        <span className="text-gray-500 group-hover:text-blue-900">{step.id}</span>
                      </span>
                    )}

                    <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-blue-900">{step.name}</span>
                  </span>
                </Link>

                {stepIdx !== steps.length - 1 ? (
                  <>
                    {/* Arrow separator for lg screens and up */}
                    <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                      <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                        <path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke" stroke="currentcolor" strokeLinejoin="round" />
                      </svg>
                    </div>
                  </>
                ) : null}
              </li>
            ))}
          </ol>
        </nav>
      </div>
    </div>
  );
}
