import { useContext, useEffect } from "react";
import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react";
import { Group, JobTitle, Participant } from "../../types";
import { formatPhoneNumber, stripNonNumbers } from "../../hooks/helpers";
import Button from "../../components/Button";
import { XMarkIcon } from "@heroicons/react/24/outline";
import IconButton from "../../components/IconButton";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { CompanyContext } from "../../contexts/companyContext";
import useApi from "../../hooks/useApi";
import MultiSelectField from "../../components/MultiSelectField";
import AutocompleteField from "../../components/AutocompleteField";
import { useNotifications } from "../../contexts/notificationContext";
import { Formik, Form, Field, ErrorMessage } from "formik";


export default function UpdateParticipantModal(props: { participant?: Participant; allParticipants: Participant[]; open: boolean; setOpen: (value: boolean) => void }) {
  const { participant, allParticipants, open, setOpen } = props;

  const queryClient = useQueryClient();
  const companyContext = useContext(CompanyContext);
  const { getApiData, putApiData } = useApi();
  const notifications = useNotifications();

  const { data: groups } = useQuery<Group[]>({
    queryKey: ["groups", companyContext.companyId],
    queryFn: async () => getApiData(`/company/groups/with-users`),
    initialData: [],
  });

  const { data: companyJobTitles } = useQuery<JobTitle[]>({
    queryKey: ["companyJobTitles", companyContext.companyId],
    queryFn: async () => getApiData(`/company/info/job-titles`),
    initialData: [],
  });

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    jobTitle: "",
    groupNames: [] as string[],
  };

  if (participant) {
    initialValues.firstName = participant.first_name ?? "";
    initialValues.lastName = participant.last_name ?? "";
    initialValues.email = participant.email ?? "";
    initialValues.phone = participant.phone_number ?? "";
    initialValues.jobTitle = participant.company_job_title_name ?? "";
    initialValues.groupNames = participant.group_ids ? participant.group_ids.map((id) => groups.find((group) => group.group_id === id)?.group_name || "") : [];
  }

  return (
    <Transition show={open}>
      <Dialog className="relative z-10" onClose={setOpen}>
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <div className="flex justify-between">
                  <h3 className="text-xl leading-6 font-medium text-gray-800 mb-5">Update Participant</h3>
                  <IconButton icon={XMarkIcon} className="h-7 text-gray-500" onClick={() => setOpen(false)} />
                </div>

                <Formik
                  initialValues={initialValues}
                  validate={(values) => {
                    const strippedPhone = stripNonNumbers(formatPhoneNumber(values.phone));
                    const existingEmail = values.email ? allParticipants.filter(p => p.user_id !== participant?.user_id).find((p) => p.email === values.email.trim()) : null;
                    const existingPhone = strippedPhone ? allParticipants.filter(p => p.user_id !== participant?.user_id).find((p) => p.phone_number === strippedPhone.trim()) : null;

                    const errors: any = {};
                    if (!values.email && !values.phone) {
                      errors.email = "Either email or phone must be provided";
                      errors.phone = "Either email or phone must be provided";
                    }
                    if (values.email && !values.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
                      errors.email = "Must be a valid email";
                    }
                    if (values.phone && !strippedPhone.match(/^[0-9]{10}$/)) {
                      errors.phone = "Phone must be a valid 10-digit number";
                    }
                    if(existingEmail) {
                      errors.email = "A participant with this email already exists"
                    }
                    if(existingPhone) {
                      errors.phone = "A participant with this phone number already exists"
                    }
                    return errors;
                  }}
                  onSubmit={async (values: typeof initialValues, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
                    setSubmitting(true);
                    try {
                      const { response: userResponse } = await putApiData(`/company/users/${participant?.user_id}`, {
                        first_name: values.firstName.trim(),
                        last_name: values.lastName.trim(),
                        email: values.email.trim(),
                        phone_number: stripNonNumbers(values.phone),
                        company_job_title_name: values.jobTitle.trim(),
                        group_names: values.groupNames,
                      });

                      if (userResponse.status === 204) {
                        setOpen(false);
                        notifications.addNotification("Success!", "Participant updated successfully", "success");
                        queryClient.invalidateQueries({ queryKey: ["participants", companyContext.companyId] });
                        queryClient.invalidateQueries({ queryKey: ["groups", companyContext.companyId] });
                      }
                    } catch (err: any) {
                      console.error(err);
                    }
                    setSubmitting(false);
                  }}
                >
                  {({ isSubmitting, values, setFieldValue }) => (
                    <Form>
                      <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                          <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900">
                            First Name
                          </label>
                          <div className="mt-2">
                            <Field
                              type="text"
                              name="firstName"
                              id="firstName"
                              className="block w-full border border-gray-300 rounded-md p-2 flex-1 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            />
                            <ErrorMessage className="text-red-500 text-xs mt-1" name="firstName" component="div" />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900">
                            Last Name
                          </label>
                          <div className="mt-2">
                            <Field
                              type="text"
                              name="lastName"
                              id="lastName"
                              className="block w-full border border-gray-300 rounded-md p-2 flex-1 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            />
                            <ErrorMessage className="text-red-500 text-xs mt-1" name="lastName" component="div" />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            Email
                          </label>
                          <div className="mt-2">
                            <Field
                              type="email"
                              name="email"
                              id="email"
                              className="block w-full border border-gray-300 rounded-md p-2 flex-1 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            />
                            <ErrorMessage className="text-red-500 text-xs mt-1" name="email" component="div" />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
                            Phone
                          </label>
                          <div className="mt-2">
                            <Field
                              type="tel"
                              name="phone"
                              id="phone"
                              value={formatPhoneNumber(values.phone)}
                              className="block w-full border border-gray-300 rounded-md p-2 flex-1 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            />
                            <ErrorMessage className="text-red-500 text-xs mt-1" name="phone" component="div" />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label htmlFor="jobTitle" className="block text-sm font-medium leading-6 text-gray-900">
                            Job Title
                          </label>
                          <div className="mt-2">
                            <AutocompleteField
                              value={values.jobTitle}
                              setValue={(value: string) => setFieldValue("jobTitle", value)}
                              values={companyJobTitles.map((jt) => jt.company_job_title_name)}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label htmlFor="groupNames" className="block text-sm font-medium leading-6 text-gray-900">
                            Groups
                          </label>
                          <div className="mt-2">
                            <MultiSelectField
                              selectedValues={values.groupNames}
                              setSelectedValues={(value: string[]) => setFieldValue("groupNames", value)}
                              values={groups.map((g) => g.group_name)}
                              placeholder="Search or Add New..."
                            />
                          </div>
                        </div>
                      </div>

                      <div className="mt-5 sm:mt-8 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                        <Button text="Cancel" type="button" variant="secondary" className="w-full" onClick={() => setOpen(false)} />
                        <Button text="Edit" type="submit" className="w-full" submitting={isSubmitting} />
                      </div>
                    </Form>
                  )}
                </Formik>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
