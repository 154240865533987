import { FC } from 'react';

interface CoreStrengthIndicatorProps {
  greenCount: number;
  yellowCount: number;
  totalRecipients: number;
}

const CoreStrengthIndicator: FC<CoreStrengthIndicatorProps> = ({
  greenCount,
  yellowCount,
  totalRecipients,
}) => {
  const coreStrength = (greenCount + (yellowCount * 0.3)) / totalRecipients;
  const coreStrengthCount = Math.round(greenCount + (yellowCount * 0.3));
  const corePercentage = Math.round(coreStrength * 100);
  

  return (
    <div className="flex items-center justify-center w-full h-full">
      <div className="flex flex-col items-center space-y-8 w-full max-w-4xl">
        {/* Main Indicator Section */}
        <div className="flex flex-col md:flex-row items-center justify-center w-full">
          {/* Left Stats */}
          <div className="w-full md:w-1/4 text-center md:pr-6 md:border-r border-gray-200 py-4">
            <div>
              <div className="text-4xl font-bold text-gray-900">
                {coreStrengthCount}
              </div>
              <div className="mt-1 text-sm text-gray-500 uppercase tracking-wide font-medium">
                out of {totalRecipients}
              </div>
              <div className="mt-4 inline-flex items-center justify-center px-3 py-1 bg-gray-100 rounded-full">
                <span className="text-sm font-medium text-gray-600">
                  {corePercentage}%
                </span>
              </div>
            </div>
          </div>

          {/* Right Visual Section */}
          <div className="w-full md:w-3/4 mt-6 md:mt-0 md:pl-8">
            <div 
              style={{
                display: 'grid',
                gridTemplateColumns: `repeat(auto-fill, minmax(20px, 1fr))`,
                gap: '2px',
                width: '100%',
                height: 'auto',
                minHeight: '80px',
                maxHeight: '180px',
                maxWidth: '600px',
                margin: '0 auto',
                padding: '8px'
              }}
              className="mb-4 overflow-hidden"
            >
              {Array.from({ length: Math.min(totalRecipients, 250) }, (_, i) => {
                return (
                  <svg
                    key={i}
                    className={`transition-all duration-300 ${
                      i < Math.ceil((corePercentage / 100) * Math.min(totalRecipients, 250))
                        ? 'text-blue-500'
                        : 'text-gray-300'
                    }`}
                    style={{
                      width: '100%',
                      height: '100%',
                      justifySelf: 'center',
                      alignSelf: 'center'
                    }}
                    viewBox="0 0 448 512"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/>
                  </svg>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoreStrengthIndicator;
