import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/16/solid";
import { Link } from "react-router-dom";

export default function MeatballMenu(props: {
  items: {
    name: string;
    icon?: React.ForwardRefExoticComponent<Omit<React.SVGProps<SVGSVGElement>, "ref">>;
    onClick?: () => void;
    href?: string;
    color?: string;
    hide?: boolean;
  }[];
}) {
  const { items } = props;

  return (
    <Menu as="div" className="relative ml-auto">
      <MenuButton className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
        <EllipsisHorizontalIcon aria-hidden="true" className="size-5" />
      </MenuButton>
      <MenuItems
        transition
        className="absolute right-0 z-10 mt-0.5 w-38 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        {items
          .filter((i) => !i.hide)
          .map((item, index) => (
            <MenuItem key={index}>
              {item.href ? (
                <Link
                  to={item.href}
                  className="px-3 py-1 text-sm/6 text-gray-800 data-[focus]:bg-gray-50 data-[focus]:outline-none flex items-center gap-1 cursor-pointer"
                  style={{ color: item.color }}
                >
                  {item.icon && <item.icon className="size-4" />}
                  <span className="w-full truncate">{item.name}</span>
                </Link>
              ) : (
                <div
                  onClick={item.onClick}
                  className="px-3 py-1 text-sm/6 text-gray-800 data-[focus]:bg-gray-50 data-[focus]:outline-none flex items-center gap-1 cursor-pointer"
                  style={{ color: item.color }}
                >
                  {item.icon && <item.icon className="size-4" />}
                  <span className="w-full truncate">{item.name}</span>
                </div>
              )}
            </MenuItem>
          ))}
      </MenuItems>
    </Menu>
  );
}
