import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";

export default function CopyableText(props: { textToShow: string; textToCopy: string }) {
  const { textToShow, textToCopy } = props;
  const [showMessage, setShowMessage] = useState<boolean>(false);

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        setShowMessage(false);
      }, 3000);
    }
  }, [showMessage]);

  return (
    <div className="relative">
      <div className="text-md text-gray-800 flex items-center gap-1">
        <span className="">{textToShow}</span>
        <div className="flex flex-col justify-center items-center">
          <ClipboardDocumentIcon
            onClick={() => {
              navigator.clipboard.writeText(textToCopy);
              setShowMessage(true);
            }}
            className="size-5 cursor-pointer text-gray-500 hover:text-gray-800"
          />
          {showMessage && <div className="text-sm text-gray-600 absolute top-6 z-50 w-36 bg-white p-1 rounded flex justify-center">Copied to clipboard!</div>}
        </div>
      </div>
    </div>
  );
}
