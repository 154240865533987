import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../../components/Button";
import logo from "../../assets/icon_high_res.png";
import SchedulePage from "../../components/Schedule";
import { Company, Participant, Schedule, Subscription } from "../../types";
import { AuthContext, useAuth } from "../../contexts/authContext";
import AddAdministrators from "../../components/AddAdministrators";
import WelcomeFinish from "../Welcome/WelcomeFinish";
import Payment from "../../components/Payment";
import BulletSteps from "../../components/BulletSteps";
import { useQuery } from "@tanstack/react-query";
import useApi from "../../hooks/useApi";
import { CompanyContext } from "../../contexts/companyContext";
import UploadParticipantsStep from "../Welcome/UploadParticipantsStep";
import { useNotifications } from "../../contexts/notificationContext";

export default function WelcomeModal(props: { open: boolean; setOpen: (value: boolean) => void }) {
  const { open, setOpen } = props;

  const notifications = useNotifications();

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  // Schedule
  const [selectedWeek, setSelectedWeek] = useState<number>(1);
  const [selectedDay, setSelectedDay] = useState<number>(2);
  const [selectedHour, setSelectedHour] = useState<number>(10);
  const [selectedMinute, setSelectedMinute] = useState<number>(0);

  // Participants
  const [participantsUploaded, setParticipantsUploaded] = useState<boolean>(false);

  // Admins
  const [adminEmails, setAdminEmails] = useState<Array<string>>([]);

  // Payment
  const [activePayment, setActivePayment] = useState<boolean>(false);

  const cancelButtonRef = useRef(null);
  const auth = useAuth();
  const { getApiData, postApiData } = useApi();
  const companyContext = useContext(CompanyContext);

  const { data: company } = useQuery<Company>({
    queryKey: ["company", companyContext.companyId],
    queryFn: () => getApiData(`/company/info`),
  });

  const { data: schedule } = useQuery<Schedule>({
    queryKey: ["schedule"],
    queryFn: () => getApiData(`/company/schedule`),
  });

  const { data: paymentInfo } = useQuery<Subscription>({
    queryKey: ["payment", companyContext.companyId],
    queryFn: () => getApiData(`/company/payment/info`),
    retry: false,
  });

  useEffect(() => {
    if (schedule) {
      setSelectedWeek(schedule.week_of_month);
      setSelectedDay(schedule.day_of_week);
      setSelectedHour(schedule.hour);
      setSelectedMinute(schedule.minute);
    }
  }, [schedule]);

  useEffect(() => {
    if (paymentInfo && paymentInfo.stripe_subscription_status === "active") {
      setActivePayment(true);
    }
  }, [paymentInfo]);

  let steps = [
    {
      id: "start",
      content: (
        <div className="mt-5">
          <div className="mx-auto flex h-36 w-36 items-center justify-center content-center rounded-full">
            <img src={logo} className="w-3/4" />
          </div>
          <div className="text-center">
            <Dialog.Title as="h3" className="font-semibold leading-6 text-gray-500 lg:text-4xl text-lg">
              Welcome to Strata Intel!
            </Dialog.Title>
            <div className="mt-8">
              <p className="text-gray-500 text-lg">We're excited to have you on board. Let's get you up and running with your Strata Intel portal.</p>
            </div>
          </div>
        </div>
      ),
      onNext: () => setCurrentStep(currentStep + 1),
      show: true,
    },
    {
      id: "schedule",
      title: "Assessment Schedule",
      description: "Select the week and day your assessment will start each month",
      content: (
        <SchedulePage
          selectedWeek={selectedWeek}
          setSelectedWeek={setSelectedWeek}
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          selectedHour={selectedHour}
          setSelectedHour={setSelectedHour}
          selectedMinute={selectedMinute}
          setSelectedMinute={setSelectedMinute}
        />
      ),
      onNext: createOrUpdateSchedule,
      show: company?.has_assessments && !schedule,
    },
    {
      id: "participants",
      title: "Configure Assessment Participants",
      description: "Set up who will be partaking in the assessment",
      content: <UploadParticipantsStep onUpload={() => setParticipantsUploaded(true)} />,
      onNext: () => setCurrentStep(currentStep + 1),
      skippable: true,
      show: false, //company?.has_assessments && participants.length <= 0,
      nextDisabled: !participantsUploaded,
    },
    {
      id: "admins",
      title: "Add Additional Administrators",
      description: "Invite other admin users to have access to the Strata Intel portal and assessment results",
      content: <AddAdministrators emails={adminEmails} setEmails={setAdminEmails} />,
      onNext: addAdmins,
      skippable: true,
      show: false,
      nextDisabled: adminEmails.length <= 0,
    },
    {
      id: "payment",
      title: "Add Payment Information",
      description: "Add payment information either with credit card or ACH transfer",
      content: <Payment />,
      onNext: () => setCurrentStep(currentStep + 1),
      skippable: true,
      show: company?.is_billing && !activePayment,
    },
    {
      id: "finish",
      content: <WelcomeFinish />,
      onNext: () => setCurrentStep(currentStep + 1),
      skippable: false,
      show: true,
    },
  ];

  async function createOrUpdateSchedule() {
    setLoading(true);
    try {
      setLoading(true);
      if (auth.sessionInfo?.idToken) {
        const { response, body } = await postApiData(`/company/schedule`, {
          week_of_month: selectedWeek,
          day_of_week: selectedDay,
          hour: selectedHour,
          minute: selectedMinute,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });

        if (response.status === 401) {
          if (body.name === "TokenExpiredError") {
            window.location.reload();
          }
        }
        if (response.status !== 200) {
          console.error(response);
          setLoading(false);
          notifications.addNotification("Error", "An error occurred creating schedule, please contact a Strata administrator", "error");
          return;
        }

        setCurrentStep(currentStep + 1);
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function addAdmins() {
    if (adminEmails.length <= 0) {
      setCurrentStep(currentStep + 1);
      return;
    }
    setLoading(true);
    try {
      setLoading(true);
      if (auth.sessionInfo?.idToken) {
        const { response, body } = await postApiData(`/company/admin`, adminEmails);

        if (response.status === 401) {
          if (body.name === "TokenExpiredError") {
            window.location.reload();
          }
        }

        if (response.status === 400) {
          for (let i = 0; i < body.errors.length; i++) {
            if (body.errors[i].includes("UsernameExistsException")) {
              notifications.addNotification("Error", `The email ${adminEmails[i]} already exists`, "error");
              setLoading(false);
              return;
            }
          }
        }

        if (response.status !== 200) {
          console.error(response);
          console.error(body);
          setLoading(false);
          notifications.addNotification("Error", "An error occurred creating admin users, please contact a Strata administrator", "error");
          return;
        }

        setCurrentStep(currentStep + 1);
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  }

  steps = steps.filter((step) => step.show);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="overflow-y-scroll h-4/5 relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:p-6">
                {steps.map(
                  (step, index) =>
                    index === currentStep && (
                      <div key={index} className="min-h-full flex flex-col justify-between">
                        <div className="p-5 h-full">
                          {step.title && (
                            <div className="border-b border-gray-900/10 pb-5">
                              <div className="text-center">
                                <p className="font-semibold leading-6 text-gray-500 lg:text-3xl text-lg">
                                  Step {index}: {step.title}
                                </p>
                                <div className="mt-8">
                                  <p className="text-gray-500 text-lg">{step.description}</p>
                                </div>
                              </div>
                            </div>
                          )}
                          {step.content}
                        </div>
                        <div className="flex justify-between p-4 mt-16">
                          {index > 0 ? (
                            <Button text="Back" variant="secondary" className="w-1/4 justify-start z-10 h-12" onClick={() => setCurrentStep(index - 1)} />
                          ) : (
                            <div className="w-1/4 justify-start"></div>
                          )}
                          <BulletSteps step={index} setStep={setCurrentStep} count={steps.length} />
                          {index < steps.length - 1 && (
                            <div className="w-1/4 justify-end z-10">
                              <Button text="Next" className="w-full h-12" onClick={step.onNext} submitting={loading} disabled={step.nextDisabled} />
                              {step.skippable && (
                                <p
                                  onClick={() => setCurrentStep(currentStep + 1)}
                                  className="mt-3 text-sm font-semibold leading-6 text-strataBlue hover:underline cursor-pointer"
                                >
                                  Skip for now
                                </p>
                              )}
                            </div>
                          )}
                          {index === steps.length - 1 && (
                            <Button
                              text="Get Started"
                              className="w-1/4 justify-end z-10"
                              onClick={() => {
                                setOpen(false);
                                window.location.href = "/home";
                              }}
                            />
                          )}
                        </div>
                      </div>
                    )
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
