import { useState } from "react";
import Button from "../../components/Button";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import useApi from "../../hooks/useApi";
import { useNotifications } from "../../contexts/notificationContext";

export default function InviteOthers() {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { postApiData } = useApi();
  const notifications = useNotifications();

  async function addAdmin() {
    if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      setError("Email is not valid");
      return;
    }
    setError("");
    setSubmitting(true);
    try {
      const { response } = await postApiData(`/company/admin`, [{ email, companyRoleId: null }]);

      if (response.ok) {
        notifications.addNotification("Success!", `Invite successfully sent to ${email}`, "success");
      } else {
        notifications.addNotification("Error", "An error occurred adding new admin, please try again later", "error");
      }
    } catch (err) {
      notifications.addNotification("Error", "An error occurred adding new admin, please try again later", "error");
    }
    setSubmitting(false);
  }

  return (
    <div>
      <label htmlFor="email" className="text-sm/6 font-medium text-gray-900">
        Email
      </label>
      <div className="mt-2 flex gap-2">
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          placeholder="user@example.com"
          className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-strataBlue sm:text-sm/6"
        />
        <Button onClick={addAdmin} text="Invite" icon={PaperAirplaneIcon} submitting={submitting} className="px-6" />
      </div>
      <div className="text-sm text-red-500 mt-2">{error}</div>
    </div>
  );
}
