import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Alert, AlertType, Company, HeroIcon } from "../../types";
import { useCompany } from "../../contexts/companyContext";
import useApi from "../../hooks/useApi";
import { BellIcon, BellSlashIcon, ClipboardDocumentIcon, ClipboardDocumentListIcon, ClipboardIcon, ExclamationCircleIcon, ExclamationTriangleIcon, EyeIcon, UserPlusIcon, UsersIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useNotifications } from "../../contexts/notificationContext";
import { Link, useNavigate } from "react-router-dom";
import MeatballMenu from "../../components/MeatballMenu";
import colors from "tailwindcss/colors";

export default function AlertList() {
  const { companyId } = useCompany();
  const { getApiData, putApiData } = useApi();
  const queryClient = useQueryClient();
  const notifications = useNotifications();
  const navigate = useNavigate();

  const { data: alerts } = useQuery<Alert[]>({
    queryKey: ["alerts", companyId],
    queryFn: () => getApiData(`/user/alerts`),
    initialData: [],
  });

  async function markAsRead(alertId: number) {
    const { response } = await putApiData(`/user/alert/${alertId}`, { is_read: true });

    if (response.ok) {
      await queryClient.invalidateQueries({ queryKey: ["alerts", companyId] });
    } else {
      notifications.addNotification("Error", "An unexpected error occured, please try again later", "error");
    }
  }

  if (alerts.filter((a) => !a.is_read && (!a.company_id || a.company_id === companyId)).length <= 0) {
    return (
      <div className="text-center mt-8">
        <BellSlashIcon className="size-6 mx-auto text-gray-400" />
        <p className="mt-1 text-sm text-gray-500">No current alerts or action items</p>
      </div>
    );
  }

  const alertTypeStyles: Record<
    number,
    {
      icon: HeroIcon;
      color: string;
    }
  > = {
    [AlertType.GroupStress]: {
      icon: ExclamationCircleIcon,
      color: colors.yellow[500],
    },
    [AlertType.ClinicalStress]: {
      icon: ExclamationCircleIcon,
      color: colors.yellow[500],
    },
    [AlertType.ParticipationRate]: {
      icon: ClipboardDocumentListIcon,
      color: colors.gray[500],
    },
    [AlertType.CoreStrengthIndicator]: {
      icon: UserPlusIcon,
      color: colors.gray[500],
    },
    [AlertType.Disengaged]: {
      icon: ExclamationCircleIcon,
      color: colors.yellow[500],
    },
  };

  return (
    <ul className="">
      {alerts
        .filter((a) => !a.is_read && (!a.company_id || a.company_id === companyId))
        .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
        .map((alert, index) => {
          const style = alertTypeStyles[alert.alert_type_id];
          return (
            <li key={alert.alert_id} style={style ? {borderColor: style.color}: {borderColor: colors.gray[500]}} className={`bg-white py-5 px-5 mb-3 rounded-lg shadow flex justify-between border`}>
              <div className="flex min-w-0 gap-x-4 items-center">
                {style ? <style.icon className="size-8" style={{ color: style.color }} /> : <ExclamationCircleIcon className="text-gray-600 size-8"/>}
                <div className="min-w-0 flex-auto">
                  <p className="text-sm/6 font-semibold text-gray-900">{alert.alert_message}</p>
                  <p className="mt-1 flex text-xs/5 text-gray-500">{new Date(alert.created_at).toLocaleDateString()}</p>
                </div>
              </div>
              <div className="flex shrink-0 items-center gap-x-6">
                <MeatballMenu
                  items={[
                    { name: "View", href: alert.alert_link!, icon: EyeIcon, hide: !alert.alert_link },
                    { name: "Dismiss", onClick: () => markAsRead(alert.alert_id), icon: XMarkIcon, color: colors.red[500] },
                  ]}
                />
              </div>
            </li>
          );
        })}
    </ul>
  );
}
