import React, { useEffect, useRef } from 'react';

export default function OTPInput(props: { otp: string; setOtp: (otp: string) => void }) {
  const { otp, setOtp } = props;
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    let value = e.target.value;

    // Ensure only one digit is entered
    value = value.slice(-1);

    const newOtp = otp.split('');
    newOtp[index] = value;
    setOtp(newOtp.join(''));

    // Move to the next input if a value is entered, or blur if it's the last input
    if (value) {
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1]?.focus();
      } else {
        inputRefs.current[index]?.blur();
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace') {
      if (!otp[index] && index > 0) {
        // Move focus to the previous input
        inputRefs.current[index - 1]?.focus();

        // Remove previous character
        const newOtp = otp.split('');
        newOtp[index - 1] = '';
        setOtp(newOtp.join(''));
      }
    } else if (e.key === 'ArrowLeft' && index > 0) {
      // Move focus to the left
      inputRefs.current[index - 1]?.focus();
    } else if (e.key === 'ArrowRight' && index < inputRefs.current.length - 1) {
      // Move focus to the right
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleFocus = (index: number) => {
    // Place cursor at the end of the input
    inputRefs.current[index]?.setSelectionRange(1, 1);
  };

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  return (
    <div className="flex justify-between space-x-2 mt-2">
      {Array.from({ length: 6 }).map((_, index) => (
        <input
          key={index}
          type="text"
          maxLength={1}
          value={otp[index] ?? ''}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onFocus={() => handleFocus(index)}
          className="w-12 h-12 border-2 border-gray-300 rounded text-center text-xl focus:outline-none focus:border-strataBlue [&::-webkit-inner-spin-button]:appearance-none caret-transparent"
          ref={(el) => (inputRefs.current[index] = el!)}
        />
      ))}
    </div>
  );
}
