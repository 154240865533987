"use client";

import { useState } from "react";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import Button from "../../components/Button";
import useApi from "../../hooks/useApi";
import { useNotifications } from "../../contexts/notificationContext";
import { useNavigate } from "react-router";
import { useQueryClient } from "@tanstack/react-query";
import { useCompany } from "../../contexts/companyContext";

export default function ParticipantAutomationModal(props: { open: boolean; setOpen: (value: boolean) => void }) {
  const { open, setOpen } = props;
  const [username, setUsername] = useState<string>("");
  const [publicKey, setPublicKey] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { postApiData } = useApi();
  const notifications = useNotifications();
  const queryClient = useQueryClient();
  const { companyId } = useCompany();

  async function createSFTPUser() {
    setSubmitting(true);

    if (!username || !publicKey) {
      setError("Username and public key are required");
    }

    try {
      const { response, body } = await postApiData("/company/sftp/user", {
        username,
        publicKey,
      });

      if (response.ok) {
        queryClient.invalidateQueries({ queryKey: ["sftpUsers", companyId] });
        notifications.addNotification("Success!", "SFTP user successfully added");
        setOpen(false);
        setUsername("");
        setPublicKey("");
        setError("");
      } else {
        if (body.type === "DuplicateUsername") {
          setError("Username has already been taken, please choose another");
        } else if (body.type === "InvalidPublicKey") {
          setError("Public key is invalid");
        } else {
          notifications.addNotification("Error", "An unexpected error occured, please try again later", "error");
        }
      }
    } catch (err) {
      console.log(err);
    }
    setSubmitting(false);
  }

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="px-4 sm:px-0">
              <h3 className="font-semibold leading-6 text-gray-900">Participant Automation</h3>
              <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                Integrate your HR provider software by setting up an employee list via SFTP. Contact a Strata Intel representive for assistance in setting this up at{" "}
                <a href="mailto:support@strataintel.com" className="text-strataBlue underline cursor-pointer">
                  support@strataintel.com
                </a>
              </p>
            </div>
            <div className="sm:col-span-3 mt-3">
              <label htmlFor="first-name" className="block text-sm/6 font-medium text-gray-900">
                Username
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  onChange={(e) => setUsername(e.target.value)}
                  className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                />
              </div>

              <div className="col-span-full mt-5">
                <label htmlFor="about" className="block text-sm/6 font-medium text-gray-900">
                  Public Key
                </label>
                <div className="mt-2">
                  <textarea
                    rows={3}
                    onChange={(e) => setPublicKey(e.target.value)}
                    className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                    defaultValue={""}
                  />
                </div>
              </div>
            </div>
            <div className="text-sm text-red-500 mt-2">{error}</div>

            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <Button text="Cancel" variant="secondary" onClick={() => setOpen(false)} />
              <Button text="Add User" type="button" onClick={() => createSFTPUser()} submitting={submitting} />
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
