import { Bar } from "react-chartjs-2";
import colors from "tailwindcss/colors";
import { BarElement, ChartData } from "chart.js";
import { Chart as ChartJS } from "chart.js";
import { useState } from "react";
import { PercentBadgeIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import Toggle from "../../components/Toggle";
import customIcons from "../../assets/customIcons";
import { GroupResult, StrataJobTitleResult } from "../../types";
import { countGreen, countRed, countYellow } from "./helpers";

ChartJS.register(BarElement);

function isGroupResult(result: GroupResult | StrataJobTitleResult): result is GroupResult {
  return "group_name" in result;
}

export default function GroupCategoryComparison(props: { results: GroupResult[] | StrataJobTitleResult[]; printable?: boolean }) {
  const { results, printable } = props;

  const [calcType, setCalcType] = useState<"Count" | "Percentage">("Count");

  const colorRed = colors.red[300];
  const colorYellow = colors.yellow[300];
  const colorGreen = colors.green[400];
  const colorGray = "#D1D5DB";

  const chartLabels = results.map((result) => (isGroupResult(result) ? result.group_name : result.strata_job_title_name));

  const data: ChartData<"bar", (number | null)[], string> = {
    labels: chartLabels,
    datasets: [
      {
        label: "",
        data: calcType === "Count" ? results.map((result) => countRed(result)) : results.map((result) => countRed(result) / result.total_respondents), // This array is for all red values (value will follow index value of label.  Example red [0,1], groupName = [j, t] j=0 t=1)
        backgroundColor: [colorRed],
        stack: "Red", // confusing - stacks are bars
      },
      {
        label: "",
        data: calcType === "Count" ? results.map((result) => countYellow(result)) : results.map((result) => countYellow(result) / result.total_respondents),
        backgroundColor: [colorYellow],
        stack: "Yellow",
      },
      {
        label: "",
        data: calcType === "Count" ? results.map((result) => countGreen(result)) : results.map((result) => countGreen(result) / result.total_respondents),
        backgroundColor: [colorGreen],
        stack: "Green",
      },
      {
        label: "",
        data: calcType === "Count" ? results.map((result) => result.disengaged_count) : results.map((result) => result.disengaged_count / result.total_respondents),
        backgroundColor: [colorGray],
        stack: "Gray",
      },
    ],
  };

  return (
    <div className={`${printable ? "h-full" : "h-[85%]"} relative`}>
      {!printable && (
        <div className="w-full inline-flex  justify-end text-gray-500 hover:text-gray-700 cursor-pointer">
          <Toggle
            value={calcType === "Percentage"}
            setValue={(value) => (value ? setCalcType("Percentage") : setCalcType("Count"))}
            offIcon={customIcons.percentSign}
            onIcon={customIcons.user}
          />
        </div>
      )}
      <Bar
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "top" as const,
              display: false,
            },
            datalabels: { display: false },
          },
          scales: {
            y: {
              title: {
                text: calcType === "Percentage" ? "Percent of Respondents" : "Number of Respondents",
                display: true,
              },
              ticks: {
                format: {
                  style: calcType === "Percentage" ? "percent" : undefined,
                },
                stepSize: calcType === "Percentage" ? 0.05 : 5,
                callback: function (value) {
                  if (calcType === "Percentage") {
                    //@ts-ignore
                    return value * 100 + "%"; // Round to integer and add the percentage symbol
                  } else {
                    return value;
                  }
                },
              },
              max: calcType === "Percentage" ? 1 : undefined,
            },
            x: {
              grid: {
                display: true,
              },
            },
          },
        }}
        data={data}
      />
    </div>
  );
}
