export type HeroIcon = React.ForwardRefExoticComponent<
  Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
    title?: string;
    titleId?: string;
  } & React.RefAttributes<SVGSVGElement>
>

export interface RespondentDemographics {
  job_title_id: number;
  age_id: number;
  years_experience_id: number;
  gender_id: number;
}

// export interface Participant {
//   user_id: number;
//   first_name: string | null;
//   last_name: string | null;
//   company_id: number;
//   email: string | null;
//   phone_number: string | null;
//   company_job_title_id: number | null;
//   company_job_title_name: string | null;
//   strata_job_title_id: number;
//   hire_date: Date;
//   terminated_date: Date;
//   created_at: Date;
// }

export interface Participant {
  user_id: number;
  first_name: string | null;
  last_name: string | null;
  company_id: number;
  email: string | null;
  phone_number: string | null;
  company_job_title_id: number | null;
  company_job_title_name: string | null;
  strata_job_title_id: number;
  hire_date: Date;
  terminated_date: Date;
  group_ids: number[];
  created_at: Date;
}

export interface ParticipantUpload {
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  phone_number: string | null;
  job_title: string | null;
  group_names: string[];
}

export interface Company {
  company_id: number;
  company_name: string;
  company_type_id: number;
  company_type_name?: string;
  company_address_1: string;
  company_address_2: string;
  company_city: string;
  company_state: string;
  company_zip?: string;
  created_at?: Date;
  payment_info_id?: number;
  parent_company_id?: number;
  has_assessments: boolean,
  is_billing: boolean,
  view_siblings: boolean
}

export interface PaymentInfo {
  payment_info_id?: number,
  stripe_subscription_id: string,
  stripe_subscription_status: string,
  stripe_customer_id: string,
  stripe_price_id: string
}

export interface Survey {
  survey_id: number;
  company_id: number;
  company_name: string;
  survey_name: string;
  start_date: Date;
  end_date: Date;
  status_id: number;
  status_name: string;
  si_value: string | null;
  at_risk_quotient: number | null;
  mental_health_value: string | null;
  sleep_value: string | null;
  diet_value: string | null;
  exercise_value: string | null;
  hydration_value: string | null;
  red_count: number | null;
  yellow_count: number | null;
  total_recipients: number;
  green_count: number | null;
  disengaged_count: number | null;
  response_rate: string | null;
  created_at: Date;
}

export type SurveyWithResults = {
  survey_id: number;
  company_id: number;
  company_name: string;
  survey_name: string;
  start_date: Date;
  end_date: Date;
  created_at: Date;
  results: SurveyResult | null;
  groupResults: GroupResult[] | null;
  strataJobTitleResults: StrataJobTitleResult[] | null;
}

export type SurveyResult = { survey_result_id: number, survey_id: number, si_value: number } & Results;

export type GroupResult = { group_result_id: number, survey_id: number, group_id: number, group_name: string } & Results;

export type StrataJobTitleResult = { group_result_id: number, survey_id: number, strata_job_title_id: number, strata_job_title_name: string } & Results;

export type CompanySurveyResult = Company & { surveys: (Survey & { results: SurveyResult })[] };

export type Results = {
  mental_health_value: number;
  sleep_value: number;
  diet_value: number;
  exercise_value: number;
  hydration_value: number;
  high_red_count: number;
  mid_red_count: number;
  low_red_count: number;
  high_yellow_count: number;
  mid_yellow_count: number;
  low_yellow_count: number;
  high_green_count: number;
  mid_green_count: number;
  disengaged_count: number;
  total_recipients: number;
  total_respondents: number;
}

export type EconomicRiskCosts = {
  employeeTurnover: number,
  sequentialTask: number,
  workersComp: number,
  total: number
}

export type CompanySurvey = Company & Survey;

export interface CompanySurveySummary {
  survey_id: number;
  company_id: number;
  company_name: string;
  survey_name: string;
  si_value: string;
  mental_health_value: string;
  sleep_value: string;
  diet_value: string;
  exercise_value: string;
  hydration_value: string;
  red_count: number;
  yellow_count: number;
  total_recipients: number;
  green_count: number;
  disengaged_count: number;
  response_rate: number;
  created_at: Date;
};

export interface CompanyHealth {
  adverse_events: number;
  company_health_id: number;
  company_id: number;
  created_at: Date;
  new_employees: number;
  terminated_employees: number;
}

export type TrendChartData = {
  value: number;
  date: Date;
};

export type Dataset = {
  label: string;
  data: TrendChartData[];
  color?: string;
};

export interface Respondent {
  respondent_id: number;
  age_id: number;
  years_experience_id: number;
  gender_id: number;
  company_id: number;
  strata_job_title_id: number | null;
  diet_value: string;
  end_date: string;
  exercise_value: string;
  hydration_value: string;
  mental_health_value: string;
  sleep_value: string;
  start_date: string;
  status_id: number;
  survey_id: number;
  group_ids: number[];
  created_at: string;
}

export type YearsExperience = {
  years_experience_id: number,
  years_experience_name: string
}

export interface Response {
  age_id: number;
  age_name: string;
  company_id: number;
  company_job_title_id: number;
  company_job_title_name: string;
  created_at: string;
  end_date: string;
  gender_id: number;
  gender_name: string;
  input_type_id: number;
  likert_score: number;
  option_choice_id: number;
  option_choice_name: string;
  option_group_id: number;
  option_group_index: number;
  page_number: number;
  question_id: number;
  question_title: string;
  respondent_id: number;
  response_created_at: string;
  response_id: number;
  section_id: number;
  si_value: string;
  start_date: string;
  status_id: number;
  strata_job_title_id: number;
  survey_id: number;
  survey_name: string;
  survey_section_id: number;
  updated_at: string;
  years_experience_id: number;
  years_experience_name: string;
}

export type FullUser = {
  company_id: number;
  company_job_title_id: number;
  company_job_title_name: string;
  created_at: Date;
  email: string;
  hire_date: Date;
  is_active: boolean;
  phone_number: string;
  strata_job_title_id: number;
  strata_job_title_name: string;
  terminated_date: Date;
  user_id: number;
}

export type CsvUser = {
  email: string,
  phoneNumber: string,
  title: string
}

export type CognitoAdmin = {
  UserAttributes: Array<{ Name: string, Value: string }>,
  Enabled: boolean,
  UserCreateDate: string,
  UserLastModifiedDate: string,
  UserStatus: string,
  Username: string
}

export type SurveyKey = {
  is_taken: Date;
  expiration_date: Date;
  email_send_date?: Date;
  survey_key: string;
  survey_key_id?: number;
  survey_id: number;
  user_id: number;
}

export type SurveyKeyUser = FullUser & SurveyKey;

export type Subscription = {
  id?: number,
  stripe_subscription_id: string,
  stripe_subscription_status: string,
  stripe_subscription_quantity: number,
  stripe_subscription_start_date: Date,
  stripe_subscription_end_date: Date | null,
  stripe_customer_id: string
}

export type CreateSchedule = {
  weekOfMonth: number;
  dayOfWeek: number;
  hour: number;
  minute: number;
  ampm: string;
  timezone: string;
}

export type SurveySchedule = {
  survey_schedule_id: number,
  company_id: number,
  week_of_month: number,
  day_of_week: number,
  hour: number,
  minute: number,
  timezone: string
}

export type Schedule = {
  survey_schedule_id?: number,
  week_of_month: number,
  day_of_week: number,
  hour: number,
  minute: number,
  timezone: string,
  company_id?: number
}

export type Group = {
  group_id: number,
  group_name: string,
  company_id: number
}

export type UserGroup = {
  user_group_id: number,
  group_id: number,
  group_name: string,
  user_id: number,
  email: string,
  phone_number: string
}

export type JobTitle = {
  company_job_title_id: number,
  company_job_title_name: string,
  strata_job_title_id: number | null,
  companyId: number
}

export type StrataJobTitle = {
  strata_job_title_id: number,
  strata_job_title_name: string
}

export type CompanyRole = {
  company_role_id: number,
  company_role_name: string,
  company_id: number,
  is_admin: boolean
}

export type UserCompanyRole = {
  user_company_role_id: number,
  cognito_user_id: string,
  company_role_id: number,
  company_role_name: string,
  company_id: number,
  is_admin: boolean
}

export type CompanyEvent = {
  company_event_id: number;
  event_name: string;
  event_description: string | null;
  company_id: number;
  event_date: Date;
}

export type SFTPUser = {
  sftp_user_id: number,
  username: string,
  public_key: string,
  company_id: number
}

export type ParticipantColumnMapping = {
  columnName: string,
  columnIndex: number,
  mapping: ParticipantColumn | null
}

export type RiskInput = {
  average_salary: number;
  average_settlement: number;
  average_workers_comp_payout: number;
  bad_debt_percentage: string;
  company_id: number;
  replacement_cost_percentage: string;
  risk_input_id: number;
  total_revenue: number;
}

export type Alert = {
  alert_id: number,
  cognito_user_id: string,
  company_id: number | null,
  alert_message: string,
  alert_link: string | null,
  mobile_alert_link: string | null,
  alert_type_id: number,
  alert_type_name: string,
  alert_priority_id: number,
  alert_priority_name: string,
  priority_level: number,
  is_read: boolean,
  created_at: Date
}

export type EmailIdentity ={
  email_identity_id: number,
  company_id: number,
  email: string,
  is_verified: boolean
}

export enum ParticipantColumn {
  "first_name" = 0,
  "last_name" = 1,
  "email" = 2,
  "phone_number" = 3,
  "job_title" = 4,
  "group_names" = 5
}

export enum ActivityType {
  Register = 1,
  Login = 2,
  Logout = 3,
  ImportParticipants = 4,
  AddParticipant = 5,
  UpdateParticipant = 6,
  DeleteParticipant = 7,
  CreateAssessmentSchedule = 8,
  UpdateAssessmentSchedule = 9,
  ChangePassword = 10,
  EnableMFA = 11,
  AddAdmin = 12,
  StartSubscription = 13,
}

export enum AlertType {
  GroupStress = 1,
  ClinicalStress = 2,
  ParticipationRate = 3,
  CoreStrengthIndicator = 4,
  Disengaged = 5,
}

export enum AlertPriority {
  High = 1,
  Medium = 2,
  Low = 3
}