import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import { AuthContext, useAuth } from "../../contexts/authContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { CompanyHealth, JobTitle, SFTPUser, StrataJobTitle } from "../../types";
import useApi from "../../hooks/useApi";
import { CompanyContext } from "../../contexts/companyContext";
import Button from "../../components/Button";
import Spinner from "../../components/Spinner";
import Alert from "../../components/Alert";
import { useNotifications } from "../../contexts/notificationContext";
import Divider from "../../components/Divider";
import { ClipboardDocumentIcon, Cog6ToothIcon, EllipsisVerticalIcon, TrashIcon } from "@heroicons/react/24/outline";
import ParticipantAutomationModal from "./ParticipantAutomation";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/react";
import MeatballMenu from "../../components/MeatballMenu";
import colors from "tailwindcss/colors";
import CopyableText from "../../components/CopyableText";
import { ConfirmModalType, useConfirmationModal } from "../../contexts/confirmationModalContext";

export default function Participants() {
  const [jobTitleChanges, setJobTitleChanges] = useState<JobTitle[]>([]);
  const { getApiData, putApiData, deleteApiData } = useApi();
  const companyContext = useContext(CompanyContext);
  const notifications = useNotifications();
  const [openAutomationModal, setOpenAutomationModal] = useState<boolean>(false);
  const confirmation = useConfirmationModal();
  const queryClient = useQueryClient();

  const { isPending: strataJobTitlesIsPending, data: strataJobTitles } = useQuery<StrataJobTitle[]>({
    queryKey: ["strataJobTitles", companyContext.companyId],
    queryFn: async () => getApiData(`/company/info/strata-job-titles`),
    initialData: [],
  });

  const { isPending: companyJobTitlesIsPending, data: companyJobTitles } = useQuery<JobTitle[]>({
    queryKey: ["companyJobTitles", companyContext.companyId],
    queryFn: async () => getApiData(`/company/info/job-titles`),
    initialData: [],
  });

  const { data: sftpUsers } = useQuery<SFTPUser[]>({
    queryKey: ["sftpUsers", companyContext.companyId],
    queryFn: async () => getApiData(`/company/sftp/users`),
    initialData: [],
  });

  async function deleteSFTPUser(sftpUserId: number) {
    const { response } = await deleteApiData(`/company/sftp/user/${sftpUserId}`);

    if (response.ok) {
      notifications.addNotification("Success!", "Successfully deleted SFTP user");
      queryClient.invalidateQueries({ queryKey: ["sftpUsers", companyContext.companyId] });
    } else {
      notifications.addNotification("Error", "An unexpected error occurred, please try again later", "error");
    }
  }

  async function updateJobTitleMappings() {
    const { response, body } = await putApiData("/company/info/job-titles", jobTitleChanges);

    if (response.status === 200) {
      notifications.addNotification("Success", "Mappings updated successfully");
      setJobTitleChanges([]);
    } else {
      console.error(response);
      console.error(body);
      notifications.addNotification("Error", "An unexpected error occurred, please try again later", "error");
    }
  }

  if (strataJobTitlesIsPending || companyJobTitlesIsPending) {
    return <Spinner />;
  }

  return (
    <div className="">
      <ParticipantAutomationModal open={openAutomationModal} setOpen={setOpenAutomationModal} />
      <div className="border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <div className="px-4 sm:px-0">
              <h3 className="text-sm font-medium leading-6 text-gray-900">Participant Automation</h3>
              <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Integrate your HR provider software</p>
            </div>
            {sftpUsers.length > 0 ? (
              <div className="sm:col-span-2">
                <Alert title="Enabled" message="Participant automation is enabled for your account" type="Success" />
                <ul className="divide-y divide-gray-100">
                  {sftpUsers.map((sftpUser, index) => (
                    <li key={index} className="flex justify-between gap-x-6 py-5">
                      <div className="">
                        <div className="text-xs text-gray-500">Username</div>
                        <CopyableText textToShow={sftpUser.username} textToCopy={sftpUser.username} />
                      </div>
                      <div className="text-sm">
                        <div className="text-xs text-gray-500">Public Key</div>
                        <CopyableText textToShow={`${sftpUser.public_key.substring(0, 20)}...`} textToCopy={sftpUser.public_key} />
                      </div>
                      <div className="text-sm">
                        <div className="text-xs text-gray-500">SFTP Url</div>
                        <CopyableText textToShow={`sftp.strataintel.com`} textToCopy={"sftp.strataintel.com"} />
                      </div>
                      <div className="flex shrink-0 items-center gap-x-6">
                        <MeatballMenu
                          items={[
                            {
                              name: "Delete User",
                              icon: TrashIcon,
                              color: colors.red[500],
                              onClick: async () =>
                                await confirmation({
                                  title: "Are you sure?",
                                  message: "Are you sure you want to delete this SFTP user? You will no longer be able to push files to Strata Intel with this user.",
                                  type: ConfirmModalType.danger,
                                  onConfirm: async () => await deleteSFTPUser(sftpUser.sftp_user_id),
                                }),
                            },
                          ]}
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                <Button text="Automate" icon={Cog6ToothIcon} onClick={() => setOpenAutomationModal(true)} />
              </dd>
            )}
          </div>
          <Divider heading={""} />
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <div className="px-4 sm:px-0">
              <h3 className="text-sm font-medium leading-6 text-gray-900">Participant Mapping</h3>
              <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Match the job tilte name clinical Strata Intel job category</p>
            </div>
            {strataJobTitles.length === 0 || companyJobTitles.length === 0 ? (
              <Alert
                title="No Job Titles"
                message="No job titles have been assigned to participants. Please assign job titles, then come back to assign mappings."
                type="Warning"
              />
            ) : (
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                <div className="-mx-4 mb-5 mt-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Facility Title
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                          Strata Intel Title
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {companyJobTitles.map((companyTitle, index) => (
                        <tr key={index}>
                          <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                            {companyTitle.company_job_title_name}
                          </td>
                          <td className=" px-3 py-4 text-sm text-gray-500 lg:table-cell">
                            <select
                              className="bg-gray-50 rounded-md border border-gray-300"
                              defaultValue={companyTitle.strata_job_title_id ?? 0}
                              onChange={(e) => {
                                companyTitle.strata_job_title_id = parseInt(e.target.value) > 0 ? parseInt(e.target.value) : null;
                                const index = jobTitleChanges.findIndex((cjt: any) => cjt.company_job_title_id === companyTitle.company_job_title_id);
                                if (index > -1) {
                                  let copyOfState = [...jobTitleChanges];
                                  copyOfState[index] = companyTitle;
                                  setJobTitleChanges(copyOfState);
                                } else {
                                  setJobTitleChanges([...jobTitleChanges, companyTitle]);
                                }
                              }}
                            >
                              <option value={0}>-- No Job Title Assigned --</option>
                              {strataJobTitles.map((strataTitle, index) => (
                                <option key={index} value={strataTitle.strata_job_title_id}>
                                  {strataTitle.strata_job_title_name}
                                </option>
                              ))}
                            </select>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {jobTitleChanges.length > 0 && <Button text="Update Mapping" onClick={() => updateJobTitleMappings()} />}
              </dd>
            )}
          </div>
          <Divider heading={""} />
        </dl>
      </div>
    </div>
  );
}
