import { GroupResult, StrataJobTitleResult } from "../../types";
import { getResponseRate } from "./helpers";

function isGroupResult(result: GroupResult | StrataJobTitleResult): result is GroupResult {
  return "group_name" in result;
}

export default function GroupParticipationRates(props: { results: GroupResult[] | StrataJobTitleResult[] }) {
  const { results } = props;
  return (
    <div className="h-[85%]">
      <div className="space-y-6 mt-6 h-full overflow-scroll">
        {results.map((result, i) => {
          return (
            <div key={i} className="flex justify-between gap-2 items-center">
              <p className="font-medium text-gray-600 text-sm">{isGroupResult(result) ? result.group_name : result.strata_job_title_name}</p>
              <p className="text-gray-900 font-semibold">{getResponseRate(result).toFixed(0)}%</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
