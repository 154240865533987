import { FC } from 'react';
import colors from "tailwindcss/colors";

interface StressBreakdownBarProps {
  green: number;
  yellow: number;
  red: number;
  disengaged: number;
}

const StressBreakdownBar: FC<StressBreakdownBarProps> = ({
  green,
  yellow,
  red,
  disengaged,
}) => {
  const total = green + yellow + red + disengaged;
  const greenPercent = (green / total) * 100;
  const yellowPercent = (yellow / total) * 100;
  const redPercent = (red / total) * 100;
  const disengagedPercent = (disengaged / total) * 100;

  // Square root transformation for visual scaling
  const scalePercent = (percent: number) => Math.sqrt(percent) * 10;
  const totalScaled = scalePercent(greenPercent) + scalePercent(yellowPercent) + 
                      scalePercent(redPercent) + scalePercent(disengagedPercent);

  // Calculate scaled widths while preserving actual percentages for display
  const getScaledWidth = (percent: number) => 
    (scalePercent(percent) / totalScaled) * 100;

  return (
    <div className="w-full">
      <div className="h-12 flex rounded-sm overflow-hidden relative">
        {redPercent > 0 && (
          <div 
            style={{ 
              width: `${getScaledWidth(redPercent)}%`,
              backgroundColor: colors.red[400]
            }}
            className="relative group"
          >
            <span className="absolute inset-0 flex items-center justify-center text-white text-xs font-semibold whitespace-nowrap">
              {Math.round(redPercent)}%
            </span>
          </div>
        )}
        {yellowPercent > 0 && (
          <div 
            style={{ 
              width: `${getScaledWidth(yellowPercent)}%`,
              backgroundColor: colors.yellow[400]
            }}
            className="relative group"
          >
            <span className="absolute inset-0 flex items-center justify-center text-white text-xs font-semibold whitespace-nowrap">
              {Math.round(yellowPercent)}%
            </span>
          </div>
        )}
        {greenPercent > 0 && (
          <div 
            style={{ 
              width: `${getScaledWidth(greenPercent)}%`,
              backgroundColor: colors.green[400]
            }}
            className="relative group"
          >
            <span className="absolute inset-0 flex items-center justify-center text-white text-xs font-semibold whitespace-nowrap">
              {Math.round(greenPercent)}%
            </span>
          </div>
        )}
        {disengagedPercent > 0 && (
          <div 
            style={{ width: `${getScaledWidth(disengagedPercent)}%` }}
            className="bg-gray-300 relative group"
          >
            <span className="absolute inset-0 flex items-center justify-center text-gray-600 text-xs font-semibold whitespace-nowrap">
              {Math.round(disengagedPercent)}%
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default StressBreakdownBar; 